import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'assets/global.scss';
import store from 'config/create_store';
import initializeGTM, { emitGTMEvent } from './config/gtm';
import App from './routes';
import * as serviceWorker from './serviceWorker';

initializeGTM();

const layer = {
  event: 'ts_page_viewed',
  currency: 'USD',
  user_data: [
    {
      member_id: '0',
      user_name: 'Guest',
      user_id: 'tsuid',
      user_email: '',
      logged_in: 'false'
    }
  ]
};
emitGTMEvent(layer);
moment.locale('en');
const container = document.querySelector('#root');
const root = createRoot(container);
root.render(<App store={store} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
