import createSagaMiddleware from 'redux-saga';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import rootSaga from 'sagas';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  locations,
  selections,
  currentUser,
  customItinerary
} from 'reducers';

import { loadState, saveState } from 'config/local_storage';

const rootReducer = combineReducers({
  locations,
  selections,
  currentUser,
  customItinerary
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  sagaMiddleware
];

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(...middleware))
);
sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
export {
  sagaMiddleware
};
