import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import Chip from "components/chip";
import styles from "./styles.module.scss";

const TravelersAge = ({ saveSelection, updateAPIData }) => {
  const [ages, setAges] = useState([
    { name: "5 and under", value: false },
    { name: "6 - 11", value: false },
    { name: "12 - 17", value: false },
    { name: "18 - 35", value: false },
    { name: "36 - 49", value: false },
    { name: "50 - 64", value: false },
    { name: "65+", value: false },
  ]);

  const save = () => {
    if (ages.every((age) => !age.value)) {
      return;
    }
    let answer = "";
    const apiAnswer = [];
    ages.forEach((age) => {
      if (age.value) {
        answer += `${age.name}, `;
        apiAnswer.push(age.name);
      }
    });
    answer = answer.slice(0, -2);
    updateAPIData(2, apiAnswer);
    saveSelection(2, answer);
  };

  const onChange = (id) => {
    const newAges = ages.map((age) => {
      if (age.name === id) {
        return { ...age, value: !age.value };
      }
      return age;
    });
    setAges(newAges);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <div className={styles.gridContainer}>
          {ages.map((age) => (
            <div className={styles.gridItem}>
              <Chip
                handleClick={onChange}
                isActive={age.value}
                label={age.name}
                checked={age.value}
                id={age.name}
              />
            </div>
          ))}
        </div>
        <Button
          as="button"
          // onClick={save}
          kind="secondary"
          disabled={ages.every((age) => !age.value)}
          type="submit"
          className={
            ages.every((age) => !age.value) ? styles.btn : styles.active
          }
          data-gtm-action="Step3 : travelers ages"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> travelers age"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
TravelersAge.propTypes = {
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
};
export default TravelersAge;
