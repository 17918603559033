import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import moment from "moment";
import Select from "react-select";
import {
  customSelectStyles,
  customSelectTheme,
} from "components/select/config";

const ApproxDateSelect = ({ setDate }) => {
  const DEPARTURE_OPTIONS = [];
  const DEPARTURE_OPTIONS_YEAR = [];
  for (let i = 0; i < 12; i += 1) {
    const date = moment().add(i, "months").startOf("month");
    const year = moment().add(i, "year").year();
    const label = date.format("MMMM");
    DEPARTURE_OPTIONS.push({
      value: date,
      label,
    });
    DEPARTURE_OPTIONS_YEAR.push({
      value: year.toString(),
      label: year.toString(),
    });
  }

  const DURATION_OPTIONS = [];
  for (let i = 1; i <= 16; i += 1) {
    DURATION_OPTIONS.push({
      label: `${i} day${i > 1 ? "s" : ""}`,
      value: i,
    });
  }
  const [duration, setDuration] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const save = () => {
    let answer = "";
    answer = `Not sure of the exact dates, but I'd like to travel for ${duration.label} days in ${month.label} of ${year.label}.`;
    const startDate = moment(
      `${month.label} ${year.value}`,
      "MMMM YYYY"
    ).format("MM/DD/YYYY");
    // const endDate = moment(`${month.label} ${year.value}`, "MMMM YYYY")
    //   .add(duration.value)
    //   .format("MM/DD/YYYY");
    setDate(answer, startDate, duration.value);
  };

  useEffect(() => {
    if (year && month && duration) {
      save();
    }
  }, [year, month, duration]);

  return (
    <div className={styles.root}>
      <span>{`Provide a general start month and year plus a duration.`}</span>
      <div className={styles.dateContainer}>
        <Select
          value={year}
          onChange={(selectedOption) => setYear(selectedOption)}
          isSearchable={false}
          options={DEPARTURE_OPTIONS_YEAR}
          placeholder="Year"
          className={styles.select}
          styles={customSelectStyles}
          theme={customSelectTheme}
        />
        <Select
          value={month}
          onChange={(selectedOption) => setMonth(selectedOption)}
          options={DEPARTURE_OPTIONS}
          className={styles.select}
          placeholder="Months"
          isSearchable={false}
          styles={customSelectStyles}
          theme={customSelectTheme}
        />
        <Select
          value={duration}
          onChange={(selectedOption) => setDuration(selectedOption)}
          options={DURATION_OPTIONS}
          placeholder="Duration"
          className={styles.select}
          isSearchable={false}
          styles={customSelectStyles}
          theme={customSelectTheme}
        />
      </div>
    </div>
  );
};
ApproxDateSelect.propTypes = {
  setDate: T.func.isRequired,
};
export default ApproxDateSelect;
