/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as ReceiptIcon } from 'assets/images/chat-version/receipt.svg';
import { ReactComponent as ChatIcon } from 'assets/images/chat-version/chat.svg';
import T from 'prop-types';
import Dialog from 'components/dialog';
import cs from 'classnames';
import { emitGTMEvent } from 'config/gtm';
import { emitGAEvent } from 'config/ga';
import { useHistory } from 'react-router';
// import { ReactComponent as DocumentIcon } from 'assets/images/document.svg';
// import { ReactComponent as CheckIcon } from 'assets/images/check_circle.svg';
// import { ReactComponent as ChatIcon } from 'assets/images/chat.svg';
// import { ReactComponent as PlanIcon } from 'assets/images/plan.svg';
import { connect } from 'react-redux';
import GlobeLoader from 'components/globe-loader/GlobeLoader';
import Constants from 'config/constants';
import Map from 'components/map';
import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';
import Api from '../../config/api';
import { useLocation } from 'react-router-dom';
import StrideLogo from '../../components/header/logo';
import Popup from 'reactjs-popup';
import 'swiper/swiper-bundle.min.css';
import ReactGa from 'react-ga4';
import Spinner from 'components/spinner';
import ChatBot from 'components/chat/chat_bot';
import ProgressBar from 'components/chat/progress_bar';
import Summary from 'components/chat/summary';
import Destinations from 'components/destinations';
import Travelers from 'components/travelers';
import TravelersAge from 'components/travelers_age';
import Dates from 'components/dates';
import Lodging from 'components/lodging';
import TripType from 'components/trip_type';
import TravelVibe from 'components/travel_vibe';
import ThingsToDo from 'components/things_to_do';
import FirstName from 'components/first_name';
import LastName from 'components/last_name';
import PhoneNumber from 'components/phone_number';
import Email from 'components/email';
import HomeCountry from 'components/home_country';
import ContactMethod from 'components/contact_method';
import PlanningStage from 'components/planning_stage';
import { use } from 'react';
import styles from './styles';

const Completed = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  function getCenterCoordinate(coordinates) {
    // Create an array of L.LatLng objects
    const latLngs = coordinates.map((coord) => L.latLng(coord.lat, coord.lng));

    // Create a bounding box that includes all the coordinates
    const bounds = L.latLngBounds(latLngs);

    // Get the center of the bounding box
    const center = bounds.getCenter();

    return center;
  }
  const [urlLocation] = useState(() => {
    const urlParams = new URLSearchParams(location.search || '');

    return urlParams.get('location') || '';
  });

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [cordinates, setCordinates] = useState([]);
  const [countryImage, setCountryImage] = useState('');
  const [cordinatesCenter, setCordinatesCenter] = useState(null);
  const [cTCId, setCTCId] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState([{
    title: 'What country do you want to visit?',
    question: Destinations,
    answer: '',
    apiAnswer: '',
    apiAnswerTwo: [],
    apiAnswerIds: [],
    countryOne: undefined
  },
  {
    title: 'How many travelers will there be?',
    question: Travelers,
    answer: '',
    apiAnswer: 0
  },
  {
    title: 'What are the age ranges of the travelers?',
    question: TravelersAge,
    answer: '',
    apiAnswer: []
  },
  {
    title: 'When will you be traveling?',
    question: Dates,
    answer: '',
    apiAnswer: undefined,
    apiAnswerTwo: undefined,
    apiAnswerDuration: undefined
  },
  {
    title: 'What type of accommodations are you looking for?',
    question: Lodging,
    apiAnswer: undefined,
    answer: ''
  },
  {
    title: 'What type of local guide experience do you want?',
    question: TripType,
    apiAnswer: undefined,
    answer: ''
  },
  {
    title: 'Who’s traveling, and what’s your travel vibe?',
    question: TravelVibe,
    apiAnswer: undefined,
    answer: ''
  },
  {
    title: 'What cities, attractions, or activities are \'must-haves\'?',
    question: ThingsToDo,
    answer: ''
  },
  {
    title: 'Almost ready to connect you with a trip designer!',
    question: {},
    answer: ''
  },
  {
    title: 'What is your first name?',
    question: FirstName,
    answer: ''
  },
  {
    title: 'What is your last name?',
    question: LastName,
    answer: ''
  },
  {
    title: 'What is your phone number?',
    question: PhoneNumber,
    answer: ''
  },
  {
    title: 'What is your email?',
    question: Email,
    answer: ''
  },
  {
    title: 'What is your home country?',
    question: HomeCountry,
    answer: ''
  },
  {
    title: 'How would you like to be reached?',
    question: ContactMethod,
    apiAnswer: undefined,
    answer: ''
  },
  {
    title: 'Where are you in your trip planning journey?',
    question: PlanningStage,
    answer: ''
  }
  ]);
  const updateAPIData = (index, answer, answerTwo, asnwerIds, duration) => {
    const newData = [...data];
    switch (index) {
      case 0:
        newData[index].apiAnswer = answer;
        if (answerTwo != []) newData[index].apiAnswerTwo = answerTwo;
        if (asnwerIds != []) newData[index].apiAnswerIds = asnwerIds;
        if (duration != undefined) newData[index].countryOne = duration;
        setData(newData);
        break;
      case 3:
        newData[index].apiAnswer = answer;
        if (answerTwo) newData[index].apiAnswerTwo = answerTwo;
        if (duration) newData[index].apiAnswerDuration = duration;
        setData(newData);
        break;
      default:
        newData[index].apiAnswer = answer;
        setData(newData);
        break;
    }
    if (index === 0) {
      getAllCoordinates();
    }
  };

  const buildParamsItinerary = (data) => {
    const out = {
      primary_country: data[0].apiAnswer,
      additional_countries: data[0].apiAnswerTwo || [],
      travelers_number: data[1].apiAnswer,
      ages: data[2].apiAnswer,
      start_date: data[3].apiAnswer || undefined,
      end_date: data[3].apiAnswerTwo || undefined,
      duration: data[3].apiAnswerDuration,
      lodging_levels: [data[4].apiAnswer],
      trip_type: data[5].apiAnswer,
      traveller_details: data[6].answer,
      travel_details: data[7].answer,
      country_ids: data[0].apiAnswerIds || [],
    }

    // eslint-disable-next-line no-unused-vars
    return Object.fromEntries(Object.entries(out).filter(([k, v]) => !!v));
  };

  const updateContactInformation = () => {
    urlParams.set('itinerary', cTCId);
    urlParams.set('primary_country', data[0].apiAnswer);
    const params = {
      first_name: data[9].answer,
      last_name: data[10].answer,
      phone_number: data[11].answer,
      email: data[12].answer,
      home_country: data[13].answer,
      preferred_contact_method: data[14].apiAnswer,
      planning_stage: data[15].answer
    };
    const url = `/custom_trip_request/${cTCId}?primary_country=${data[0].apiAnswer}`;
    Api.put(url, params).then(({ json }) => {
    });
  };

  const createItinerary = () => {
    const params = buildParamsItinerary(data);
    Api.postTripGen('/custom_trip_request/', {
      params
    }).then(({ json }) => {
      setCTCId(json.id);
    });
  };

  const updateData = (index, answer) => {
    const newData = [...data];
    newData[index].answer = answer;
    setData(newData);
    setCurrentStep(currentStep + 1);
    if (index === data.length) {
      const itinerary = {
        destination: data[0].answer,
        travelers: data[1].answer,
        ageRanges: data[2].answer,
        dates: data[3].answer,
        lodging: data[4].answer,
        tripType: data[5].answer,
        travelVibe: data[6].answer,
        thingsToDo: data[7].answer,
        firstName: data[9].answer,
        lastName: data[10].answer,
        phoneNumber: data[11].answer,
        email: data[12].answer,
        homeCountry: data[13].answer,
        contactMethod: data[14].answer,
        planningStage: data[15].answer
      };
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // useEffect(() => {
  //   console.log('data', data);
  // }, [data]);

  function getAllCoordinates() {
    const cords = [];
    const cordCenter = [];
    const locations = data[0].apiAnswerTwo != undefined ? [data[0].apiAnswer, ...data[0].apiAnswerTwo] : [data[0].apiAnswer];
    const params = {
      countries: locations
    };
    if (data[0].countryOne != undefined) {
      const imageDesktop = data[0].countryOne?.desktop?.url?.replace('https://stride-develop.s3.amazonaws.com', 'https://static.travelstride.com');
      const imageTablet = data[0].countryOne?.tablet?.url?.replace('https://stride-develop.s3.amazonaws.com', 'https://static.travelstride.com');
      const imageMobile = data[0].countryOne?.mobile?.url?.replace('https://stride-develop.s3.amazonaws.com', 'https://static.travelstride.com');
      setCountryImage(imageDesktop);
    }
    Api.postTripGen('/coordinates/', {
      params
    }).then(({ json }) => {
      Object.values(json.coordinates).forEach((country) => {
        cords.push([country.lat, country.lng]);
        cordCenter.push({ lat: country.lat, lng: country.lng });
      });
      if (data[0].countryOne == undefined) {
        setCountryImage(json.image);
      }
      const latLngs = cordCenter.map((coord) => L.latLng(coord.lat, coord.lng));
      const bounds = L.latLngBounds(latLngs);
      const center = bounds.getCenter();
      setCordinatesCenter(center);
      setCordinates(cords);
    });
  }

  const customMarker = new Icon({
    iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const chatContainerRef = React.createRef();

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const observer = new MutationObserver(() => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: 'smooth'
    });
  });

  useEffect(() => {
    if (chatContainerRef.current) {
      observer.observe(chatContainerRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [currentStep]);

  const editStep = (index) => {
    setCurrentStep(index);
    if (screenSize.width <= 1025) {
      setOpenSummary(false);
    }
  };

  const createCTR = (ctr) => {
    const dayDetails = {
      activities: [],
      day: 0,
      description: '',
      end_city: '',
      end_city_coordinates: [],
      end_country: '',
      highlights: [],
      place_photo_url: '',
      start_city: '',
      start_city_coordinates: [],
      start_country: '',
      title: ''
    };
    const custom_trip_request = {
      primary_country_slug: ctr.primary_country,
      travelers_number: ctr.travelers_number,
      trip_type: ctr.trip_type,
      lodging_levels: ctr.lodging_levels || [],
      ages: ctr.ages || [],
      duration: ctr.duration,
      first_name: data[9].answer,
      last_name: data[10].answer,
      home_country_slug: data[13].answer,
      phone_number: data[11].answer,
      email: data[12].answer,
      budget: ctr.budget,
      currency: 'USD',
      include_flights: false,
      flexipass_initiated: false,
      skip_destination: false,
      website_code: 'CTC',
      // additionalCountries: ctr.additional_countries || [],
      // startDate: ctr.start_date,
      // endDate: ctr.end_date,
      // phoneNumber: ctr.phone_number,
      // homeCountrySlug: ctr.home_country,
      // planning_process_type: data.planning_stage|| '',
      preferredContactMethod: ctr.preferred_contact_method || [],
      overview: ctr.overview || '',
      customItinerary: {
        id: ctr.id,
        Country: ctr.primary_country,
        Itinerary: [dayDetails]
      }
    };
    customTripRequest.customItinerary.Itinerary = data.trip_itinerary.map((day, index) => ({
      activities: day.activities || [],
      day: index + 1,
      description: day.description || '',
      end_city: day.end_city || '',
      end_city_coordinates: day.end_city_coordinates || [],
      end_country: day.end_country || '',
      highlights: day.highlights || [],
      place_photo_url: day.place_photo_url || '',
      start_city: day.start_city || '',
      start_city_coordinates: day.start_city_coordinates || [],
      start_country: day.start_country || '',
      title: day.title || '',
      note: day.notes || localStorage.getItem(`itineraryNote_${itineraryString}_day${index + 1}`) || '',
      note_saved: data.are_notes_sent ? true : !!day.notes
    }));
    const formatJson = {
      id: data.id || '',
      primaryCountry: data.primary_country || '',
      additionalCountries: data.additional_countries || [],
      budget: data.budget || '',
      startDate: data.start_date || '',
      endDate: data.end_date || '',
      travelersNumber: data.travelers_number || '',
      tripType: data.trip_type || '',
      duration: data.duration || '',
      lodgingLevels: data.lodging_levels || [],
      tripItinerary: customTripRequest.customItinerary.Itinerary || data.trip_itinerary,
      status: data.status || '',
      note: data.notes || mainNote,
      note_saved: data.are_notes_sent ? true : !!data.notes,
      are_notes_sent: data.are_notes_sent || false,
      overview: data.overview || ''
    };
    setData(formatJson);
  };

  const sendToPhx = () => {
    let customTripRequest = {};
    Api.get(`/custom_trip_request/${cTCId}/?primary_country=${data[0].apiAnswer}`).then(({ json }) => {
      if (json.status === 'successful') {
        customTripRequest = createCTR(json);
      }
    });
    const params = {
      custom_trip_request: {
        primary_country_slug: data[0].apiAnswer,
        travelers_number: data[1].apiAnswer,
        trip_type: data[5].apiAnswer,
        lodging_levels: [data[4].apiAnswer === 'Luxury' ? 94 : data[4].apiAnswer === 'Premium' ? 95 : 96],
        ages: data[2].apiAnswer,
        duration: data[3].apiAnswerDuration,
        first_name: data[9].answer,
        last_name: data[10].answer,
        home_country_slug: data[13].answer,
        phone_number: data[11].answer,
        email: data[12].answer,
        budget: '22477.000000000004',
        currency: 'USD',
        include_flights: false,
        flexipass_initiated: false,
        skip_destination: false,
        website_code: 'CTC',
        itinerary_id: cTCId
      }
    };
    Api.postPHX('/v1/custom_trip_requests', params);
  };

  return (
    <>
      <div
        className={cs(styles.container, {
          [styles.receiptMobile]: screenSize.width <= 1025 && openSummary
        })}
      >
        <GlobeLoader
          active={loading}
        />
        <div
          className={cs(styles.header, {
            [styles.intro]: currentStep === 0
          })}
        >
          <div
            className={styles.logo}
          >
            <StrideLogo />
            <div>Custom Trip Creator <span>BETA</span></div>
          </div>
          {currentStep >= 1 && screenSize.width <= 1025 && (
            <div onClick={() => setOpenSummary(!openSummary)}>
              {!openSummary ? <ReceiptIcon /> : <ChatIcon />}
            </div>
          )}
        </div>
        <div
          className={cs(styles.chatContainer, {
            [styles.intro]: currentStep === 0,
            [styles.receiptMobile]: screenSize.width <= 1025 && openSummary
          })}
          ref={chatContainerRef}
        // style={currentStep >= 1 && screenSize.width <= 1025 ? { backgroundImage: `url(${countryImage})` } : {}}
        >
          <ChatBot
            createItinerary={createItinerary}
            updateContactData={updateContactInformation}
            currentStep={currentStep}
            steps={data}
            saveSelection={updateData}
            next={nextStep}
            updateAPIData={updateAPIData}
            primatyCountry={data[0].apiAnswer}
            ctcID={cTCId}
            isMobile={screenSize.width <= 1025}
          />
        </div>
        <div
          className={cs(styles.rightSide, {
            [styles.receiptMobile]: screenSize.width <= 1025 && openSummary
          })}
        >
          {currentStep >= 1 && (
            <>
              <div
                className={styles.map}
              >
                <Map
                  coordinates={cordinates}
                  coordinatesCenter={cordinatesCenter}
                  customMarker={customMarker}
                  isChat
                />
              </div>
              <div
                className={styles.summary}
                style={currentStep >= 1 ? data[0].countryOne == undefined ? { backgroundImage: `url(${countryImage})`, backgroundSize: '100%' } : { backgroundImage: `url(${countryImage})`, backgroundSize: '100vw' } : {}}
              >
                <Summary
                  currentStep={currentStep}
                  setCurrentStep={editStep}
                  steps={data}
                />
                <ProgressBar
                  currentStep={currentStep}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* {screenSize.width < 1500 && openSummary && (<Popup
        open={openSummary}
        modal
      >
        <div className={styles.modal}>
          testuibg
        </div>
      </Popup>)} */}
    </>

  );
};

const mapStateToProps = (state) => ({
  customItinerary: state.customItinerary
});

const mapDispatchToProps = (dispatch) => ({
  updateCTCID: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'id',
      data
    });
  },
  updateCTCPrimaryCountry: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'primaryCountry',
      data
    });
  },
  updateCTCNote: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'note',
      data
    });
  },
  updateCTCItinerary: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'Itinerary',
      data
    });
  }
});

Completed.propTypes = {
  customItinerary: T.object,
  updateCTCID: T.func,
  updateCTCPrimaryCountry: T.func,
  updateCTCItinerary: T.func,
  updateCTCNote: T.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Completed);
