import React from 'react';

import styles from './styles';


const Spinner = () => (
  <div className={ styles.root }>
    <div className={ styles.ldsRing }>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Spinner;
