import React, { useCallback, useRef, useEffect, useState } from 'react';
import T from 'prop-types';
import { specificWishesShape } from 'reducers/selections';
import { ReactComponent as InfoIcon } from 'assets/images/info-new.svg';

import styles from './styles';
import InfoTooltip from 'components/info-tooltip';

const placeholderWhereWhat =
  `Tell us specific cities, attractions, and/or activities
For example:
‘Paris and the Louvre’ 
or 
‘whitewater rafting in Colorado.’`;

const placeholderWhoWhy =
  `For example:
‘A family of four looking for relaxation and kid-friendly fun’ 
or 
‘a couple on a romantic getaway with wine-tasting experiences.’`;

const SpecificWishesForm = ({ travel, traveller, selectTravellerDetails, selectTravelDetails }) => {
  const textareaRef = useRef(null);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const onChangeTravelDetails = useCallback((e) => {
    selectTravelDetails({ text: e.target.value });
  }, []);

  const onChangeTravellerDetails = useCallback((e) => {
    selectTravellerDetails({ text: e.target.value });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInput = (event, type) => {
    if (screenSize.width <= 768) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
    }
    if (type === 'travelDetails') {
      onChangeTravelDetails(event);
    }
    if (type === 'travellerDetails') {
      onChangeTravellerDetails(event);
    }
  };

  return (
    <div className={styles.root}>
      <div>
        <h1 className={styles.titleTextArea}>
          <span>
            Where {''}
          </span>
          to and {' '}
          <span>
            What {''}
          </span>
          are you looking for?
          <InfoTooltip text={placeholderWhereWhat} />
        </h1>
        <textarea
          ref={textareaRef}
          placeholder={placeholderWhereWhat}
          className={styles.textarea}
          onChange={(e) => handleInput(e, 'travelDetails')}
          value={travel.text}
          expandOnFocus
        />
      </div>
      <div>
        <h1 className={styles.titleTextArea}>
          <span>
            Who’s  {''}
          </span>
          traveling and {' '}
          <span>
            what’s  {''}
          </span>
          your travel vibe?
          <InfoTooltip text={placeholderWhoWhy} />
        </h1>
        <textarea
          ref={textareaRef}
          placeholder={placeholderWhoWhy}
          className={styles.textarea}
          onChange={(e) => handleInput(e, 'travellerDetails')}
          value={traveller.text}
          expandOnFocus
        />
      </div>
    </div>
  );
};
SpecificWishesForm.propTypes = {
  data: specificWishesShape.isRequired,
  select: T.func.isRequired
};
export default SpecificWishesForm;
