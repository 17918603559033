import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { specificWishesShape } from 'reducers/selections';

import Dialog from 'components/dialog';
import Controls from 'components/controls';
import styles from './styles';
import { ReactComponent as GetQuote } from "assets/images/getQuote.svg";
import Button from 'stride-core-ui/button';
import Header from 'components/header';
import ReactGa from 'react-ga4';

const SpecificWishes = ({
  data,
  select
}) => {
  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Get quote page' });
  }, []);

  const [lastPageVisit, setLastPageVisit] = useState(null);

  useEffect(() => {
    const completedPageUrl = localStorage.getItem('completedPageUrl');
    setLastPageVisit(completedPageUrl);
  }, []);
  return (
    <>
      <Header isLastStep={false} isFirstStep={false} />

      <Dialog
        title="Thank you for submitting your custom trip request!"
      >
        <span className={styles.title}>
          You can expect to hear from us within the next 24-48 hours.
        </span>
        <br />
        <br />
        <div className='flex justify-center'>
          <GetQuote />

        </div>

        {/* <div className={styles.btnContainer}>
          <Button as="a" href="/my-custom-trip" className={styles.outlineBtn}>
            Build a new trip
          </Button>
          <Button as="a" href="/" className={styles.fillBtn}>
            Back to Travestride
          </Button>
        </div> */}

        <div className='my-auto'>
          <Controls
            prev={`${lastPageVisit}`}
            buttonLabelPrev={'Back to itinerary'}
            next={`/${location.search}`}
            buttonLabel={'Back to Travestride'}
          // nextDisabled={ !data.text.length }
          />
        </div>

      </Dialog>

    </>
  );
};
SpecificWishes.propTypes = {
  data: specificWishesShape.isRequired,
  select: T.func.isRequired
};

export default SpecificWishes;
