import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as Indicator } from "assets/images/chat-version/indicator.svg";
import styles from "./styles.module.scss";
import moment from "moment";
import cs from "classnames";
import { use } from "react";

const ProgressBar = ({ currentStep }) => {
  const [constumStep, setConstumStep] = useState(currentStep);
  const [dateType, setDateType] = useState([
    {
      id: 0,
      title: "Where",
    },
    {
      id: 1,
      title: "Who",
    },
    {
      id: 2,
      title: "When",
    },
    {
      id: 3,
      title: "Accommodations",
    },
    {
      id: 4,
      title: "Trip Type",
    },
    {
      id: 5,
      title: "Details",
    },
    {
      id: 6,
      title: "Contact",
    },
  ]);
  useEffect(() => {
    switch (currentStep) {
      case 0:
        setConstumStep(0);
        break;
      case 1:
        setConstumStep(1);
        break;
      case 2:
        setConstumStep(1);
        break;
      case 3:
        setConstumStep(2);
        break;
      case 4:
        setConstumStep(3);
        break;
      case 5:
        setConstumStep(4);
        break;
      case 6:
        setConstumStep(5);
        break;
      case 7:
        setConstumStep(5);
        break;
      case 8:
        setConstumStep(6);
        break;
      case 9:
        setConstumStep(6);
        break;
      case 10:
        setConstumStep(6);
        break;
      case 11:
        setConstumStep(6);
        break;
      case 12:
        setConstumStep(6);
        break;
      case 13:
        setConstumStep(6);
        break;
      case 14:
        setConstumStep(6);
        break;
      case 15:
        setConstumStep(6);
        break;
      default:
        setConstumStep(currentStep + 1);
        break;
    }
  }, [currentStep]);
  return (
    <div className={styles.root}>
      {dateType.map((step) => (
        <div className={styles.stepContainer}>
          {step.id === constumStep && <Indicator />}
          <div
            className={cs({
              [styles.default]: step.id > constumStep,
              [styles.active]: step.id === constumStep,
              [styles.old]: step.id < constumStep,
            })}></div>
          {step.id === constumStep && <span>{step.title}</span>}
        </div>
      ))}
    </div>
  );
};

ProgressBar.propTypes = {
  currentStep: T.number.isRequired,
};

export default ProgressBar;
