import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import { ReactComponent as Money } from "assets/images/chat-version/money.svg";
import cs from "classnames";
import styles from "./styles.module.scss";

const Lodging = ({ saveSelection, updateAPIData }) => {
  const [dateType, setDateType] = useState([
    {
      id: 1,
      name: "Standard",
      text: "Basic, comfortable, and affordable accommodations.",
      icon: [Money],
      value: false,
    },
    {
      id: 2,
      name: "Premium",
      text: "Upscale hotels with enhanced amenities and style.",
      icon: [Money, Money],
      value: false,
    },
    {
      id: 3,
      name: "Luxury",
      text: "Top-tier, luxurious hotels with premium services.",
      icon: [Money, Money, Money],
      value: false,
    },
  ]);

  const ontypeChange = (id) => {
    const newdateTypes = dateType.map((type) => {
      if (type.id === id) {
        return { ...type, value: !type.value };
      }
      return { ...type, value: false };
    });
    setDateType(newdateTypes);
  };

  const save = () => {
    if (!dateType.some((type) => type.value)) {
      return;
    }
    let answer = "I'd prefer a ";
    let apiAnswer = "";
    dateType.forEach((type) => {
      if (type.value) {
        answer += `${type.name.toLocaleLowerCase()} stay.`;
        apiAnswer = type.name;
      }
    });
    updateAPIData(4, apiAnswer);
    saveSelection(4, answer);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <div className={styles.container}>
          {dateType.map((type) => (
            <div
              className={cs(styles.typeContainer, {
                [styles.active]: type.value,
              })}
              onClick={() => ontypeChange(type.id)}>
              <div className={styles.iconContainer}>
                {type.icon.map((Icon, index) => (
                  <Icon key={index} />
                ))}
              </div>
              <h1>{type.name}</h1>
              <span>{type.text}</span>
            </div>
          ))}
        </div>
        <Button
          as="button"
          // onClick={save}
          kind="secondary"
          type="submit"
          disabled={!dateType.some((type) => type.value)}
          className={cs(styles.btn, {
            [styles.active]: dateType.some((type) => type.value),
          })}
          data-gtm-action="Step5 : lodging"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> lodging"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
Lodging.propTypes = {
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
};
export default Lodging;
