/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "../../components/dialog";
import ContactDetailsForm from "./form";
import Controls from "../../components/controls";
import SelectionsSubmit from "../../components/selections_submit";
import styles from "./styles";
import Api from "../../config/api";
import selections from "sagas/selections";
import Header from 'components/header';
import moment from 'moment';
import ReactGa from 'react-ga4';

const lodging = [
  { id: 96, name: "Standard" },
  { id: 95, name: "Premium" },
  { id: 94, name: "Luxury" }
];

const ContactDetails = ({ selections, currentUser, updateId }) => {
  const formRef = useRef();
  const history = useHistory();
  const [pending, setPending] = useState(false);
  const [countries, setCountries] = useState([]);
  const id = localStorage.getItem("customTripResponse");
  // const id = "2e873f2b-2090-47fb-888d-0fbd373f6d9e";
  const primary_country = selections.locations && selections.locations[0] ? selections.locations[0].countryName : '';
  const urlParams = new URLSearchParams(location.search || '');
  urlParams.set('itinerary', id);
  urlParams.set('primary_country', primary_country);

  // useEffect(() => {
  //   urlParams.set('itinerary', id);
  //   urlParams.set('primary_country', primary_country);
  // }, [id, primary_country]);

  const buildPhxParams = (form) => {
    const data = form;
    const urlParams = new URLSearchParams(window.location.search);
    const urlLocation = urlParams.get('location');
    const websiteCode = (window.location.hostname).includes('ustoa') || urlLocation === 'ustoa' ? 'ustoa' : urlLocation || 'travelstride';

    // eslint-disable-next-line max-len
    const date = form.dates.departureMonth && data.dates.departureMonth.value
      ? moment(data.dates.departureMonth.value)
      : undefined;
    const out = {
      primary_country_slug: data.locations && data.locations[0] ? data.locations[0].countryName.toLowerCase().replace(/\s+/g, '-') : '',
      ages: data.travelers.ages.filter((i) => i.value).map((age) => age.name),
      travelers_number: data.travelers.travelersNumber.value,
      start_date: data.dates.startDate
        ? data.dates.startDate.format('MM/DD/YYYY')
        : date
          ? date.format('MM/DD/YYYY')
          : undefined,
      end_date: data.dates.startDate
        ? data.dates.endDate.format('MM/DD/YYYY')
        : date
          ? date.add(data.dates.duration.value, 'days').format('MM/DD/YYYY')
          : undefined,
      duration: data.dates.duration ? data.dates.duration.value : undefined,
      trip_type: data.tripType,
      lodging_levels: lodging.find((l) => l.name === data.lodging).id,
      budget: data.budget.amount
        ? data.budget.amount.toString()
        : undefined,
      travel_details: data.travelDetails.text,
      traveller_details: data.travellerDetails.text,
      additional_countries: data.locations && data.locations.length ? data.locations.slice(1).map((l) => l.countryName) : '',
      // additionalCountries: data.moreLocations.length ? data.moreLocations.map((l) => l.slug) : '',
      // skip_destination: data.skipDestination,
      // website_code: websiteCode,
    }

    // eslint-disable-next-line no-unused-vars
    return Object.fromEntries(Object.entries(out).filter(([k, v]) => !!v));
  };

  const onNextClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPending(true);
    let id = localStorage.getItem("customTripResponse");
    urlParams.set('itinerary', id);
    urlParams.set('primary_country', primary_country);

    if (formRef.current && formRef.current.handleSubmit) {
      const context = formRef.current.getFormikContext();
      context.submitForm().then(() => {
        const validationErrors = context.validate(context.values || {});
        if (!Object.keys(validationErrors).length) {
          const params = {
            first_name: context.values.firstname,
            last_name: context.values.lastname,
            email: context.values.email,
            phone_number: context.values.countrycode + context.values.phone,
            home_country: context.values.homeCountry,
            planning_stage: context.values.planningStage,
          };
          // const PhxUrl = `/v1/custom_trip_requests`;
          // const phxParams = {
          //   custom_trip_request: {
          //     ...buildPhxParams(selections),
          //     first_name: context.values.firstname,
          //     last_name: context.values.lastname,
          //     email: context.values.email,
          //     phone_number: context.values.countrycode + context.values.phone,
          //     home_country_slug: context.values.homeCountry.toLowerCase().replace(/\s+/g, '-'),
          //     planning_stage: context.values.planningStage,
          //     website_code: 'CTC'
          //   }
          // };
          // Api.postPHX(PhxUrl, phxParams)
          const url = `/custom_trip_request/${id}?primary_country=${primary_country}`;
          Api.put(url, params).then(({ json }) => {
            setTimeout(() => {
              history.push(`/completed?${urlParams.toString()}`);
            }, 10000);
            setPending(false);
          });
        }
      });
    } else {
      setPending(false);
    }
  };

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Contact Details page' });
    Api.get("/countries").then(({ json }) => {
      const countriesData = json?.data?.continents?.map(
        ({ continent_en_name, countries, continent_id }) => ({
          id: continent_id,
          label: continent_en_name,
          options: countries.map(
            ({ country_en_name: label, country_id: value }) => ({
              value,
              label,
            })
          ),
        })
      );
      setCountries(countriesData);
    });
  }, []);

  // useEffect(() => {
  //   console.log(selections)
  //   if (selections.error) {
  //     alert(selections.error.base)
  //   } else if (selections.id) {
  //     history.push('/completed')
  //   }
  // }, [selections])

  return (
    <>
      <Header isLastStep={false} isFirstStep={false} />
      <Dialog title="How do you prefer we reach you?">
        <span className={styles.title}>
          We'll use this to notify you when you have new messages from your Trip
          Designer.
        </span>
        <ContactDetailsForm formRef={formRef} countries={countries} />
        <Controls
          next={`/completed?${urlParams.toString()}`}
          nextDisabled={pending}
          onNextClick={onNextClick}
          buttonLabel={"Create Itinerary"}
        />
      </Dialog>
      <SelectionsSubmit />
    </>
  );
};

export default ContactDetails;
