import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import styles from "./styles.module.scss";

const Travelers = ({ saveSelection, updateAPIData }) => {
  const [numberTravelers, setNumberTravelers] = useState(0);

  const save = () => {
    if (numberTravelers === 0) {
      return;
    }
    let answer = numberTravelers.toString();
    if (numberTravelers > 1) {
      answer += " people";
    } else {
      answer += " person";
    }
    saveSelection(1, answer);
    updateAPIData(1, parseInt(numberTravelers, 10));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <input
          type="number"
          id="numInput"
          name="numInput"
          min="1"
          max="100"
          placeholder="Number of travelers"
          onChange={(e) => {
            setNumberTravelers(e.target.value);
          }}
          className={styles.input}
        />
        <Button
          as="button"
          // onClick={ save }
          kind="secondary"
          disabled={numberTravelers > 0}
          className={numberTravelers <= 0 ? styles.btn : styles.active}
          type="submit"
          data-gtm-action="Step2 : number of travelers"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> number of travelers"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
Travelers.propTypes = {
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
};
export default Travelers;
