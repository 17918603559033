import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import Completed from './completed';

const mapStateToProps = ({ customItinerary }) => ({
  customItinerary
});

const mapDispatchToProps = (dispatch) => ({
  updateCTCID: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'id',
      data
    });
  },
  updateCTCPrimaryCountry: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'primaryCountry',
      data
    });
  },
  updateCTCNote: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'note',
      data
    });
  },
  updateCTCItinerary: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'Itinerary',
      data
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Completed);
