import React from "react";
import ReactDOM from "react-dom";
import T from "prop-types";
import cs from "classnames";
import styles from "./InfoTooltip.module.scss";
import { usePopperTooltip } from "react-popper-tooltip";
import cn from "classnames";
import "react-popper-tooltip/dist/styles.css";
import { ReactComponent as Info } from "assets/images/info-new.svg";

const InfoTooltip = ({
  text,
  className,
  iconClassName,
  children,
  component,
}) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: "top-start",
      interactive: true,
      trigger: ["click", "hover"],
    });

  return (
    <div className={cs(styles.root, className)}>
      <div ref={setTriggerRef}>
        {children ? (
          children
        ) : (
          <Info
            className={cs(styles.info, iconClassName)}
            ref={setTriggerRef}
          />
        )}
      </div>
      {visible &&
        ReactDOM.createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: cn(
                "tooltip-container",
                styles.text,
                styles.tooltip,
                className
              ),
            })}>
            <>{text || component}</>
          </div>,
          document.body
        )}
    </div>
  );
};

export default InfoTooltip;

InfoTooltip.propTypes = {
  text: T.string,
  className: T.string,
  iconClassName: T.string,
  component: T.element,
  children: T.element,
};
InfoTooltip.defaultProps = {
  text: undefined,
  className: "",
  iconClassName: "",
  component: undefined,
  children: undefined,
};
