import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import styles from "./styles.module.scss";
import moment from "moment";
import cs from "classnames";
import { ReactComponent as AddNote } from "assets/images/addnote.svg";
import { set } from "react-ga";
import Typed from "react-typed";

const UserMessage = ({ text }) => {
  return <div className={styles.root}>{text}</div>;
};

UserMessage.propTypes = {
  text: T.string.isRequired,
};

export default UserMessage;
