import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import Button from "stride-core-ui/button";
import { ReactComponent as Email } from "assets/images/chat-version/email.svg";
import { ReactComponent as Phone } from "assets/images/chat-version/phone.svg";
import { ReactComponent as Text } from "assets/images/chat-version/text.svg";
import { ReactComponent as Whatsapp } from "assets/images/chat-version/whatsapp.svg";
import cs from "classnames";

const ContactMethod = ({ saveSelection, updateAPIData }) => {
  const [dateType, setDateType] = useState([
    {
      id: 1,
      name: "Email",
      icon: Email,
      value: false,
    },
    {
      id: 2,
      name: "Phone",
      icon: Phone,
      value: false,
    },
    {
      id: 3,
      name: "Text",
      icon: Text,
      value: false,
    },
    {
      id: 4,
      name: "Whatsapp",
      icon: Whatsapp,
      value: false,
    },
  ]);
  const ontypeChange = (id) => {
    const newdateTypes = dateType.map((type) => {
      if (type.id === id) {
        return { ...type, value: !type.value };
      }
      return type;
    });
    setDateType(newdateTypes);
  };

  const save = () => {
    if (!dateType.some((type) => type.value)) {
      return;
    }
  
    const formatMapping = {
      Email: "email",
      Phone: "call_me",
      Text: "text_me",
      Whatsapp: "whatsapp",
    };
  
    let answer = "You can ";
    let apiAnswer = [];
    dateType.forEach((type) => {
      if (type.value) {
        answer += type.name.toLocaleLowerCase() + ", ";
        apiAnswer.push(formatMapping[type.name]);
      }
    });
    answer = answer.slice(0, -2);
    answer += " me.";
    updateAPIData(14, apiAnswer);
    saveSelection(14, answer);
  };

  return (
    <div className={styles.root}>
      <span>{`Select all that apply.`}</span>
      <div className={styles.container}>
        {dateType.map((type) => (
          <div
            className={cs(styles.typeContainer, {
              [styles.active]: type.value,
            })}
            onClick={() => ontypeChange(type.id)}>
            <type.icon />
            <h1>{type.name}</h1>
          </div>
        ))}
      </div>
      <Button
        as={"button"}
        onClick={save}
        kind={"secondary"}
        disabled={!dateType.some((type) => type.value)}
        className={cs(styles.btn, {
          [styles.active]: dateType.some((type) => type.value),
        })}
        data-gtm-action="Step14: Contact method"
        data-gtm-category="Custom trip chat flow"
        data-gtm-label="Custom trip chat flow -> Contact method"
        data-gtm-trigger="click">
        Submit
      </Button>
    </div>
  );
};
ContactMethod.propTypes = {
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
};
export default ContactMethod;
