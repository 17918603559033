import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import styles from "./styles.module.scss";

const Email = ({ saveSelection }) => {
  const [text, setText] = useState("");

  const validateEmail = (emailField) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (emailField && emailField.match(isValidEmail)) {
      return true;
    }

    return false;
  };
  const save = () => {
    if (text.length <= 0 || !validateEmail(text)) {
      return;
    }
    saveSelection(12, text);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (text.length <= 0 || !validateEmail(text)) {
      return false;
    }

    save();

    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Your best email address"
          className={styles.input}
          name="email"
        />
        <Button
          as="button"
          // onClick={ save }
          type="submit"
          kind="secondary"
          disabled={text.length <= 0 || !validateEmail(text)}
          className={
            text.length <= 0 || !validateEmail(text)
              ? styles.btn
              : styles.active
          }
          data-gtm-action="Step12: Email"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> Email"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
Email.propTypes = {
  saveSelection: T.func.isRequired,
};
export default Email;
