import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import { ReactComponent as Calendar } from "assets/images/chat-version/calendar.svg";
import { ReactComponent as Clock } from "assets/images/chat-version/clock.svg";
import { ReactComponent as NotSure } from "assets/images/chat-version/not-sure.svg";
import ExactDatePicker from "components/exact_date_picker";
import ApproxDateSelect from "components/approx_date_select";
import DurationSelect from "components/duration_select";
import cs from "classnames";
import styles from "./styles.module.scss";

const Dates = ({ saveSelection, updateAPIData }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateType, setDateType] = useState([
    {
      id: 1,
      name: "I know my exact travel dates",
      icon: Calendar,
      date: "",
      ApiAnswerStart: undefined,
      ApiAnswerEnd: undefined,
      value: false,
      selector: ExactDatePicker,
    },
    {
      id: 2,
      name: "I know a rough idea of my travel dates",
      icon: Clock,
      date: "",
      ApiAnswerStart: undefined,
      duration: 0,
      value: false,
      selector: ApproxDateSelect,
    },
    {
      id: 3,
      name: "I haven’t chosen my dates yet",
      icon: NotSure,
      date: "",
      duration: 0,
      value: false,
      selector: DurationSelect,
    },
  ]);

  const ontypeChange = (id) => {
    const newdateTypes = dateType.map((type) => {
      if (type.id === id) {
        return { ...type, value: !type.value };
      }
      return { ...type, value: false };
    });
    setDateType(newdateTypes);
  };

  const save = () => {
    if (!dateType.some((type) => type.value && type.date)) {
      return;
    }
    let answer = "";
    const apiAnswer = "";
    const apiAnswerTwo = "";
    dateType.forEach((type) => {
      if (type.value) {
        answer = type.date;
        switch (type.id) {
          case 1:
            updateAPIData(3, type.ApiAnswerStart, type.ApiAnswerEnd);
            break;
          case 2:
            updateAPIData(3, type.ApiAnswerStart, undefined, [], type.duration);
            break;
          case 3:
            updateAPIData(3, undefined, undefined, [], type.duration);
            break;
        }
      }
    });
    saveSelection(3, answer);
  };

  const setDate = (date, startDate, endDate) => {
    const newdateTypes = dateType.map((type) => {
      if (type.value) {
        const ApiAnswerStart = startDate;
        const ApiAnswerEnd = endDate;
        switch (type.id) {
          case 1:
            return { ...type, date, ApiAnswerStart, ApiAnswerEnd };
          case 2:
            return {
              ...type,
              date,
              ApiAnswerStart,
              duration: ApiAnswerEnd,
            };
          case 3:
            return { ...type, date, duration: ApiAnswerStart };
          default:
            return { ...type, date };
        }
      } else {
        return { ...type, date: "" };
      }
    });
    setDateType(newdateTypes);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        {dateType.map((type) => (
          <>{type.value && <type.selector setDate={setDate} />}</>
        ))}
        <div className={styles.dateContainer}>
          {dateType.map((type) => (
            <div
              className={cs(styles.dateTypeContainer, {
                [styles.active]: type.value,
              })}
              onClick={() => ontypeChange(type.id)}>
              <type.icon />
              <h1>{type.name}</h1>
            </div>
          ))}
        </div>
        <Button
          as="button"
          // onClick={save}
          kind="secondary"
          disabled={!dateType.some((type) => type.value && type.date)}
          type="submit"
          className={cs(styles.btn, {
            [styles.active]: dateType.some((type) => type.value && type.date),
          })}
          data-gtm-action="Step4 : dates"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> Fourth step"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
Dates.propTypes = {
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
};
export default Dates;
