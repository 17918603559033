import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import { specificWishesShape } from 'reducers/selections';

import Dialog from 'components/dialog';
import Controls from 'components/controls';
import SpecificWishesForm from './form';
import styles from './styles';
import Chip from 'components/chip';
import moment from 'moment';
import Api from 'config/api';
import Header from 'components/header';
import { useHistory } from "react-router-dom";
import GlobeLoader from 'components/globe-loader/GlobeLoader';
import ReactGa from 'react-ga4';
import travelers from 'routes/travelers';

const OPTIONS = [{
  title: 'Off the beaten path'
}, {
  title: 'Highlights'
}, {
  title: 'Curious'
},
{
  title: 'Spiritual'
}, {
  title: 'Luxurious'
},
{
  title: 'Fast paced'
}, {
  title: 'Historical'
},
{
  title: 'Romantic'
},
{
  title: 'Family'
},
{
  title: 'Adventurous'
}];

const SpecificWishes = ({
  travel,
  traveller,
  selections,
  selectTravelDetails,
  selectTravellerDetails
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState([]);


  const onSelect = (name) => {
    setSelectedType((prevSelectedType) => {
      if (prevSelectedType.includes(name)) {
        return prevSelectedType.filter(item => item !== name);
      } else {
        return [...prevSelectedType, name];
      }
    });
  };

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const buildSelectionsParams = (form) => {
    const data = form;
    const urlParams = new URLSearchParams(window.location.search);
    const urlLocation = urlParams.get('location');
    const websiteCode = (window.location.hostname).includes('ustoa') || urlLocation === 'ustoa' ? 'ustoa' : urlLocation || 'travelstride';

    // eslint-disable-next-line max-len
    const date = form.dates.departureMonth && data.dates.departureMonth.value
      ? moment(data.dates.departureMonth.value)
      : undefined;
    const out = {
      primary_country: data.locations && data.locations[0] ? data.locations[0].countryName : '',
      ages: data.travelers.ages.filter((i) => i.value).map((age) => age.name),
      travelers_number: data.travelers.travelersNumber.value,
      start_date: data.dates.startDate
        ? data.dates.startDate.format('MM/DD/YYYY')
        : date
          ? date.format('MM/DD/YYYY')
          : undefined,
      end_date: data.dates.startDate
        ? data.dates.endDate.format('MM/DD/YYYY')
        : date
          ? date.add(data.dates.duration.value, 'days').format('MM/DD/YYYY')
          : undefined,
      duration: data.dates.duration ? data.dates.duration.value : undefined,
      trip_type: data.tripType,
      lodging_levels: [data.lodging],
      budget: data.budget.amount
        ? data.budget.amount.toString()
        : undefined,
      travel_details: data.travelDetails.text,
      traveller_details: data.travellerDetails.text,
      additional_countries: data.locations && data.locations.length ? data.locations.slice(1).map((l) => l.countryName) : '',
      // additionalCountries: data.moreLocations.length ? data.moreLocations.map((l) => l.slug) : '',
      // skip_destination: data.skipDestination,
      // website_code: websiteCode,
    }

    // eslint-disable-next-line no-unused-vars
    return Object.fromEntries(Object.entries(out).filter(([k, v]) => !!v));
  };

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // setLoading(true);
    const params = buildSelectionsParams(selections);
    Api.postTripGen('/custom_trip_request', {
      params: params
    }).then(({ json }) => {
      localStorage.setItem('customTripResponse', json.id);
      // setLoading(false);
      history.push(`/contact-details${location.search}`);
    });
  }

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Specific Wishes page' });
  }, []);

  return (
    <>
      <GlobeLoader active={loading} />
      <Header isLastStep={false} isFirstStep={false} />
      <Dialog
        title="Tell us more about your dream trip!"
      >
        <span className={styles.title}>
          Share what you’re looking for to make this trip unique.
          Tell us as much or little as you want.
        </span>
        {/* {screenSize.width >= 768 && (
          <div className={styles.selectedTags}>
            {OPTIONS.map((item) => (
              <Chip id={item.title} label={item.title} isActive={selectedType.includes(item.title)} handleClick={onSelect} />
            ))}
          </div>
        )} */}
        <SpecificWishesForm
          travel={travel}
          traveller={traveller}
          selectTravelDetails={selectTravelDetails}
          selectTravellerDetails={selectTravellerDetails}
        />

        <Controls
          prev={`/what-kind-of-trip-are-you-looking-for${location.search}`}
          next={`/contact-details${location.search}`}
          buttonLabel={'Next'}
          onNextClick={submit}
        // nextDisabled={ !data.text.length }
        />
      </Dialog>
    </>
  );
};
SpecificWishes.propTypes = {
  data: specificWishesShape.isRequired,
  select: T.func.isRequired
};

export default SpecificWishes;
