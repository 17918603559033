import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import styles from "./styles.module.scss";

const FirstName = ({ saveSelection }) => {
  const [text, setText] = useState("");

  const save = () => {
    if (text.length <= 0) {
      return;
    }
    saveSelection(9, text);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter your first name"
          className={styles.input}
          name="first_name"
        />
        <Button
          as="button"
          // onClick={save}
          kind="secondary"
          disabled={text.length <= 0}
          type="submit"
          className={text.length <= 0 ? styles.btn : styles.active}
          data-gtm-action="Step9: First Name"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> First Name"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
FirstName.propTypes = {
  saveSelection: T.func.isRequired,
};
export default FirstName;
