import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import moment from "moment";
import cs from "classnames";
import { ReactComponent as AddNote } from "assets/images/addnote.svg";
import { set } from "react-ga";
import styles from "./styles.module.scss";

const Notes = ({
  updateNote,
  defaultNote,
  id,
  noteSend,
  placeholder = "What would you like the trip designer to know about this whole trip?",
}) => {
  const [note, setNote] = useState(defaultNote || "");
  const [active, setActive] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleNoteChange = (event) => {
    if (event.target.value.length > 0) {
      if (id > 0) {
        updateNote(event.target.value, id, false);
      } else {
        updateNote(event.target.value, false);
      }
      if (!noteSend) setSaved(false);
      setActive(true);
    } else {
      setActive(false);
    }
    setNote(event.target.value);
  };

  const handleSaveNote = () => {
    // console.log("note", save);
    if (saved) return;
    if (id > 0) {
      updateNote(note, id, true);
      setSaved(true);
      setActive(false);
    } else {
      updateNote(note, true);
      setSaved(true);
      setActive(false);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.Container}>
        <div className={styles.dayTitle}>Notes for your trip designer</div>
        <textarea
          placeholder={placeholder}
          className={styles.textArea}
          value={note}
          disabled={noteSend}
          onChange={handleNoteChange}
        />
        <Button
          onClick={handleSaveNote}
          size="medium"
          disabled={noteSend ? true : !active}
          className={cs(styles.btn, {
            [styles.active]: note.length > 0 && active,
          })}
          data-gtm-action="save_note"
          data-gtm-category="Custom trip Itinerary"
          data-gtm-label="Custom trip Itinerary -> save_note"
          data-gtm-trigger="click">
          <AddNote />
          {noteSend ? (
            <div> Save Note </div>
          ) : saved ? (
            <div>Edit Note</div>
          ) : (
            <div>Save Note</div>
          )}
        </Button>
      </div>
    </div>
  );
};

Notes.propTypes = {
  updateNote: T.func.isRequired,
  defaultNote: T.string,
  id: T.number,
  noteSend: T.bool,
};

export default Notes;
