import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import Select from "react-select";
import {
  customSelectStyles,
  customSelectTheme,
} from "components/select/config";
import cs from "classnames";

const DurationSelect = ({ setDate }) => {
  const DURATION_OPTIONS = [];
  for (let i = 1; i <= 16; i += 1) {
    DURATION_OPTIONS.push({
      label: `${i} day${i > 1 ? "s" : ""}`,
      value: i,
    });
  }
  const [duration, setDuration] = useState(null);

  const save = () => {
    let answer = "";
    answer = `I'm not sure when I'd like to go, but I'd like it to be ${duration.label}.`;
    setDate(answer, duration.value);
  };

  useEffect(() => {
    if (duration) {
      save();
    }
  }, [duration]);

  return (
    <div className={styles.root}>
      <span>{`Haven’t decided yet? Just give us an ideal duration.`}</span>
      <div className={styles.dateContainer}>
        <Select
          value={duration}
          onChange={(selectedOption) => setDuration(selectedOption)}
          options={DURATION_OPTIONS}
          placeholder="Duration"
          className={cs(styles.select, {
            [styles.active]: duration,
          })}
          isSearchable={false}
          styles={customSelectStyles}
          theme={customSelectTheme}
        />
      </div>
    </div>
  );
};
DurationSelect.propTypes = {
  setDate: T.func.isRequired,
};
export default DurationSelect;
