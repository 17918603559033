/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import T from 'prop-types';
import Dialog from 'components/dialog';
import cs from 'classnames';
import { emitGTMEvent } from 'config/gtm';
import { emitGAEvent } from 'config/ga';
import { useHistory } from 'react-router';
// import { ReactComponent as DocumentIcon } from 'assets/images/document.svg';
// import { ReactComponent as CheckIcon } from 'assets/images/check_circle.svg';
// import { ReactComponent as ChatIcon } from 'assets/images/chat.svg';
// import { ReactComponent as PlanIcon } from 'assets/images/plan.svg';
import Button from 'stride-core-ui/button';
import styles from './styles';
import { connect } from 'react-redux';
import GlobeLoader from 'components/globe-loader/GlobeLoader';
import { ReactComponent as SendNote } from 'assets/images/send-notes.svg';
import Constants from 'config/constants';
import Day from 'components/day';
import Notes from 'components/notes';
import Map from 'components/map';
import { MapContainer, TileLayer, Marker, FeatureGroup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import { Icon } from 'leaflet';
import { select } from 'redux-saga/effects';
import selections from 'sagas/selections';
import Api from '../../config/api';
import { set } from 'react-ga';
import L from 'leaflet';
import moment, { duration } from 'moment';
import { useLocation } from 'react-router-dom';
import StrideLogo from '../../components/header/logo';
import { ReactComponent as PassengerIcon } from 'assets/images/passenger.svg';
import Popup from 'reactjs-popup';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, EffectFade, Lazy, SwiperOptions } from 'swiper';
import ReactGa from 'react-ga4';
import { ReactComponent as AddNote } from "assets/images/addnote.svg";
import Spinner from 'components/spinner';

SwiperCore.use([Autoplay, EffectFade, Lazy])


const Completed = ({
  customItinerary,
  updateCTCID,
  updateCTCPrimaryCountry,
  updateCTCItinerary,
  updateCTCNote,
}) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  //const itineraryString = new URLSearchParams('itinerary')
  const itineraryString = urlParams.get('itinerary')
  function getCenterCoordinate(coordinates) {
    // Create an array of L.LatLng objects
    const latLngs = coordinates.map(coord => L.latLng(coord.lat, coord.lng));

    // Create a bounding box that includes all the coordinates
    const bounds = L.latLngBounds(latLngs);

    // Get the center of the bounding box
    const center = bounds.getCenter();

    return center;
  }
  const [urlLocation] = useState(() => {
    const urlParams = new URLSearchParams(location.search || '');

    return urlParams.get('location') || '';
  });

  useEffect(() => {
    const cleanedPathname = window.location.pathname.replace('/my-custom-trip', '');
    localStorage.setItem('completedPageUrl', cleanedPathname + window.location.search);
  }, []);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [overviewOpen, setOverviewOpen] = useState(true);
  const [notesSend, setNotesSend] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [coordinatesCenter, setCoordinatesCenter] = useState(null);
  const [data, setData] = useState({
    primary_country: '',
    budget: '',
    start_date: '',
    end_date: '',
    travelers_number: '',
    tripType: '',
    activities: [],
    description: '',
    highlights: [],
    title: '',
    startCity: '',
    endCity: '',
    duration: '',
    lodgingLevels: '',
    tripItinerary: [],
    status: '',
    note: '',
    note_send: false,
    are_notes_sent: false,
    overview: '',
  });

  // useEffect(() => {
  //   const eventData = {
  //     event: 'CustomTripFormSubmit',
  //     category: 'CustomTripFormSubmit',
  //     action: 'CustomTripFormSubmitAction',
  //     label: 'CustomTripFormSubmit'
  //   };
  //   emitGTMEvent(eventData);
  //   emitGAEvent(eventData);

  //   // To make it impossible to go back after submission
  //   history.push({
  //     pathname: 'completed'
  //   });
  //   const unlisten = history.listen((newLocation, action) => {
  //     if (action !== 'PUSH') {
  //       // Send user back if they try to navigate back
  //       history.go(1);
  //     }
  //   });

  //   return unlisten;
  // }, []);



  const saveItinerayState = (data) => {
    let dayDetails = {
      activities: [],
      day: 0,
      description: "",
      end_city: "",
      end_city_coordinates: [],
      end_country: "",
      highlights: [],
      place_photo_url: "",
      start_city: "",
      start_city_coordinates: [],
      start_country: "",
      title: ""
    };
    let customTripRequest = {
      details: '',
      travelersNumber: data.travelers_number,
      additionalCountries: data.additional_countries || [],
      duration: data.duration,
      startDate: data.start_date,
      endDate: data.end_date,
      tripType: data.trip_type,
      phoneNumber: data.phone_number,
      homeCountrySlug: data.home_country,
      primaryCountrySlug: data.primary_country,
      budget: data.budget,
      currency: 'USD',
      websiteCode: 'travelstride CTC',
      // planning_process_type: data.planning_stage|| '',
      ages: data.ages || [],
      lodgingLevels: data.lodging_levels || [],
      preferredContactMethod: data.preferred_contact_method || [],
      overview: data.overview || '',
      customItinerary: {
        id: data.id,
        Country: data.primary_country,
        Itinerary: [dayDetails]
      }
    }
    const mainNote = localStorage.getItem(`itineraryNote_${itineraryString}`) || '';
    customTripRequest.customItinerary.Itinerary = data.trip_itinerary.map((day, index) => ({
      activities: day.activities || [],
      day: index + 1,
      description: day.description || "",
      end_city: day.end_city || "",
      end_city_coordinates: day.end_city_coordinates || [],
      end_country: day.end_country || "",
      highlights: day.highlights || [],
      place_photo_url: day.place_photo_url || "",
      start_city: day.start_city || "",
      start_city_coordinates: day.start_city_coordinates || [],
      start_country: day.start_country || "",
      title: day.title || "",
      note: day.notes || localStorage.getItem(`itineraryNote_${itineraryString}_day${index + 1}`) || '',
      note_saved: data.are_notes_sent ? true : day.notes ? true : false
    }));
    let formatJson = {
      id: data.id || '',
      primaryCountry: data.primary_country || '',
      additionalCountries: data.additional_countries || [],
      budget: data.budget || '',
      startDate: data.start_date || '',
      endDate: data.end_date || '',
      travelersNumber: data.travelers_number || '',
      tripType: data.trip_type || '',
      duration: data.duration || '',
      lodgingLevels: data.lodging_levels || [],
      tripItinerary: customTripRequest.customItinerary.Itinerary || data.trip_itinerary,
      status: data.status || '',
      note: data.notes || mainNote,
      note_saved: data.are_notes_sent ? true : data.notes ? true : false,
      are_notes_sent: data.are_notes_sent || false,
      overview: data.overview || '',
    };
    setData(formatJson);


    // if (data.id) {
    //   updateCTCID({ id: data.id });
    // }
    // if (data.primary_country) {
    //   updateCTCPrimaryCountry({ primaryCountry: data.primary_country });
    // }
    // if (customTripRequest.customItinerary.Itinerary) {
    //   updateCTCItinerary({ Itinerary: customTripRequest.customItinerary.Itinerary });
    // }

  };
  useEffect(() => {
    if (data.tripItinerary.length > 0) {
      const allNotesSaved = data.tripItinerary.every(day => day.note_saved === true);
      if (allNotesSaved && data.are_notes_sent === false) {
        setNotesSend(true);
      }
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Itinerary page' });
    const primaryCountry = urlParams.get('primary_country');
    const item_id = urlParams.get('itinerary');
    let res = Api.get(`/custom_trip_request/${item_id}/?primary_country=${primaryCountry}`)
    res.then(({ json }) => {
      if (json.status === 'successful') {
        saveItinerayState(json);
        getAllCoordinates(json.trip_itinerary);
        setLoading(false);
      }
      else {
        setInterval(() => {
          res = Api.get(`/custom_trip_request/${item_id}/?primary_country=${primaryCountry}`)
          res.then(({ json }) => {
            if (json.status === 'successful') {
              saveItinerayState(json);
              getAllCoordinates(json.trip_itinerary);
              setLoading(false);
            }
          })
        }, 10000);
      }
    })
  }, []);


  function getAllCoordinates(tripItinerary) {
    const coordinates = [];
    const coordinatesCenter = [];

    tripItinerary.forEach(item => {
      if (item.start_city_coordinates) {
        coordinates.push(item.start_city_coordinates);
        coordinatesCenter.push({
          lat: item.start_city_coordinates[0],
          lng: item.start_city_coordinates[1]
        });
      }
      if (item.end_city_coordinates) {
        coordinates.push(item.end_city_coordinates);
        coordinatesCenter.push({
          lat: item.end_city_coordinates[0],
          lng: item.end_city_coordinates[1]
        });
      }
    });
    // Create an array of L.LatLng objects
    const latLngs = coordinatesCenter.map(coord => L.latLng(coord.lat, coord.lng));

    // Create a bounding box that includes all the coordinates
    const bounds = L.latLngBounds(latLngs);

    // Get the center of the bounding box
    const center = bounds.getCenter();

    // Set the coordinates and center
    const uniqueCoordinates = Array.from(
      new Set(coordinates.map(JSON.stringify))
    ).map(JSON.parse);
    setCoordinates(uniqueCoordinates);
    setCoordinatesCenter(center);
  }

  const customMarker = new Icon({
    iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const TripTypes = [{
    name: 'shared_tour',
    title: 'Shared Tour',
    description: 'Join other travelers shared group tours.'
  }, {
    name: 'partially_guided',
    title: 'Mixed',
    description: 'Private guides for highlights and shared group for other activities'
  }, {
    name: 'independent',
    title: 'Independent',
    description: 'Independent travel with no guides.'
  }, {
    name: 'fully_guided',
    title: 'Private Guides',
    description: 'Have a local guide whenever possible.'
  }]

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const copyUrlToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      alert('URL copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy URL: ', err);
    });
  };

  const saveNotes = (notes, day) => {
    const params = {
      notes: notes,
      day: day || 0,
    };
    const url = `/custom_trip_request/notes/${data.id}?primary_country=${data.primaryCountry}`;
    Api.put(url, params).then(({ json }) => {
      if (json.status === 'updated') {
        return true;
      } else {
        return false;
      }
    });
  };




  const updateNotes = (notes, id, save) => {
    localStorage.setItem(`itineraryNote_${itineraryString}_day${id}`, notes);
    updateCTCItinerary(prevState => {
      const updatedItinerary = prevState.Itinerary.map((day, index) => {
        if (index === day - 1) {
          return {
            ...day,
            notes: notes,
            note_saved: true
          };
        }
        return day;
      });
      return { ...prevState, Itinerary: updatedItinerary };
    });
    setData(prevState => {
      const updatedItinerary = prevState.tripItinerary.map((day, index) => {
        if (index === id - 1) {
          return {
            ...day,
            note: notes,
            note_saved: false
          };
        }
        return day;
      });
      return { ...prevState, tripItinerary: updatedItinerary };
    });
    if (save) {
      setData(prevState => {
        const updatedItinerary = prevState.tripItinerary.map((day, index) => {
          if (index === id - 1) {
            return {
              ...day,
              note: notes,
              note_saved: true
            };
          }
          return day;
        });
        return { ...prevState, tripItinerary: updatedItinerary };
      });
      return saveNotes(notes, id);
    }
    return false;
  };

  const updateMainNote = (notes, save) => {
    //const itineraryId = customItinerary.id;
    localStorage.setItem(`itineraryNote_${itineraryString}`, notes);
    updateCTCNote({ note: notes });
    if (save) {
      setData(prevState => {
        return {
          ...prevState,
          note: notes,
          note_saved: true
        };
      });
      return saveNotes(notes, 0);
    }
    setData(prevState => {
      return {
        ...prevState,
        note: notes,
        note_saved: false
      };
    });
    return false
  };

  const [open, setOpen] = useState(true);
  const closeModal = () => setOpen(false);
  const [openUnsavedNotes, setOpenunsavedNotes] = useState(false);
  const closeModalNotes = () => setOpenunsavedNotes(false);
  const [tripSummary, setTripSummary] = useState(false);
  const toggleTripSummary = () => setTripSummary(!tripSummary);
  const [spinner, setSpinnerState] = useState(false);
  const [images, setImages] = useState([]);
  const [noteSendActive, setNoteSendActive] = useState(data.tripItinerary.some(day => day.note.length > 0) || data.note_saved === true);

  const setSpinnerAndCloseModal = () => {
    closeModalNotes();
    setSpinnerState(true);
  };
  useEffect(() => {
    let array = [];
    data.tripItinerary?.forEach((day) => {
      if (day.place_photo_url) {
        array.push(day.place_photo_url);
      }
    });
    setImages(array);
    setNoteSendActive(data.tripItinerary.some(day => day.note.length > 0) || data.note_saved === true);
  }, [data])

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sendNotesToDesigner = () => {
    setSpinnerAndCloseModal();
    const params = {
      are_notes_sent: true,
    };
    const url = `/custom_trip_request/${data.id}?primary_country=${data.primaryCountry}`;
    Api.patch(url, {
      params: params
    }).then(({ json }) => {
      setData(prevState => {
        return {
          ...prevState,
          are_notes_sent: true
        };
      });
      setSpinnerState(false);
    });
  }


  const saveAllNotes = () => {
    if (data.note_saved == false) {
      saveNotes(data.note, 0);
    }
    data.tripItinerary.forEach((day, index) => {
      if (day.note_saved == false) {
        saveNotes(day.note, index + 1);
      }
    });
    sendNotesToDesigner();
  }

  const SendNotes = () => {
    if (data.are_notes_sent === true) return
    const allNotesSaved = data.tripItinerary.some(day => day.note_saved === true) || (data.note_saved === true);
    if (allNotesSaved) {
      sendNotesToDesigner(data);
    } else {
      setOpenunsavedNotes(true);
    }
  };

  return (
    <>
      <div>
        <GlobeLoader active={loading} />
        <div className={styles.header}>
          <div className={styles.logo}>
            <StrideLogo />
            <div>Custom Trip Creator <span>BETA</span></div>
          </div>
          <div className={styles.tripShareDiv}>
            {screenSize.width >= 1025 && (
              <>
                <span className={styles.how} onClick={() => setOpen(true)}>
                  How it works
                </span>
                <Button
                  disabled={data.are_notes_sent || noteSendActive}
                  onClick={SendNotes}
                  size={'medium'}
                  className={cs(styles.btn, { [styles.active]: noteSendActive })}
                  data-gtm-action="send_notes"
                  data-gtm-category="Custom trip Itinerary"
                  data-gtm-label="Custom trip Itinerary -> send_notes"
                  data-gtm-trigger="click"
                >
                  <SendNote />
                  <div>
                    Send Notes
                    <span>to trip Designer</span>
                  </div>
                </Button>
              </>
            )}
            <div className='flex gap-2'>
              <Button
                data-gtm-action="Share Itinerary"
                data-gtm-category="Custom trip Itinerary"
                data-gtm-label="Custom trip Itinerary -> Itinerary"
                data-gtm-trigger="click"
                as="a" onClick={copyUrlToClipboard} size={'medium'} className={styles.fillBtn}>
                Share
                <span>Itinerary</span>
              </Button>
            </div>
          </div>
        </div>
        {!loading && (

          <div className={styles.contentDiv}>
            <div className={styles.content}>
              <div className={styles.titleDiv}>
                <div className={styles.title}>
                  <span>Preliminary</span>
                  <h2>My {data.primaryCountry}{" "}{data.additionalCountries && data.additionalCountries[0]} Trip</h2>
                </div>
                <div className={styles.descriptionDiv}>
                  {screenSize.width < 1025 && (
                    <>
                      <span className={styles.how} onClick={() => setOpen(true)}>
                        How it works
                      </span>
                      <span className={styles.how}>
                        <Button as="a" size={'medium'} className={cs(styles.btn, styles.active)}>
                          <AddNote />
                          <div>
                            Add Note
                            <span>to trip Designer</span>
                          </div>
                        </Button>
                      </span>
                    </>
                  )}
                </div>

                {screenSize.width < 1025 && (
                  <>
                    <div className={styles.mapMobile}>
                      {!loading && !open &&
                        <>
                          {/* <div className={styles.actionDiv}>
                        <button className={styles.share} onClick={copyUrlToClipboard}>
                          <ShareSvg />
                          Share trip
                        </button>
                      </div> */}
                          <div className={styles.mapContainerMobile}>
                            <Map
                              coordinates={coordinates}
                              coordinatesCenter={coordinatesCenter}
                              customMarker={customMarker}
                              isMobile={true}
                            />
                          </div>
                        </>
                      }
                    </div>
                  </>
                )}
              </div>
              <div className={styles.tripDataDiv}>
                {screenSize.width > 1025 ? (
                  <div className={styles.col}>
                    <span className={styles.title}>
                      Locations: {data.primaryCountry}{" "}{data.additionalCountries && data.additionalCountries[0]}
                    </span>
                    <div className={styles.field}>
                      <span>
                        Travelers:
                      </span>
                      <span className={styles.value}>
                        {data.travelersNumber}
                      </span>
                      <span>
                        Duration:
                      </span>
                      <span className={styles.value}>
                        {data.duration} days
                      </span>
                      <span>
                        Trip Type:
                      </span>
                      <span className={styles.value}>
                        {TripTypes.find(option => option.name === data.tripType)?.title}
                      </span>
                      <span>
                        Total Estimate:
                      </span>
                      {data?.budget > 0 ? (
                        <span className={styles.value}>
                          <span>From</span> {formatCurrency(data.budget * 0.4)} <br /><span className={styles.smaller}>up to <b>{formatCurrency(data.budget * 1.2)}</b></span>
                        </span>
                      ) :
                        (
                          <span className={styles.value}> Talk with your trip designer</span>
                        )
                      }
                    </div>
                    <span className={styles.description}>
                      Prices for this trip can vary within a range based on travel dates, hotel & accommodations, and booked activities. More finalized pricing will be available through the trip designer.
                    </span>
                  </div>
                ) : (
                  <div className={styles.col}>
                    <div
                      className={cs(styles.tripSummaryBtn)}
                      onClick={toggleTripSummary}
                    >
                      <div className={styles.title}>
                        Trip Summary
                        <div className={styles.svg}>
                          <ArrowDown className={tripSummary ? styles.rotated : ""} />
                        </div>
                      </div>

                      {tripSummary && (
                        <div>
                          <span className={styles.title}>
                            Locations: {data.primaryCountry}{" "}{data.additionalCountries && data.additionalCountries[0]}
                          </span>
                          <div className={styles.field}>
                            <span>
                              Travelers:
                            </span>
                            <span className={styles.value}>
                              {data.travelersNumber}
                            </span>
                            <span>
                              Duration:
                            </span>
                            <span className={styles.value}>
                              {data.duration} days
                            </span>
                            <span>
                              Trip Type:
                            </span>
                            <span className={styles.value}>
                              {TripTypes.find(option => option.name === data.tripType)?.title}
                            </span>
                            <span>
                              Total Estimate:
                            </span>
                            <span className={styles.value}>
                              {formatCurrency(data.budget * 0.4)} - {formatCurrency(data.budget * 1.2)}
                            </span>
                          </div>
                          <span className={styles.description}>
                            This is a preliminary estimate, subject to change based on final itinerary.
                          </span>
                        </div>
                      )}
                    </div>

                  </div>
                )}
                <div className={styles.col}>
                  <Notes noteSend={data.are_notes_sent} updateNote={updateMainNote} day={0} defaultNote={data.note?.length > 0 ? data.note : localStorage.getItem(`itineraryNote_${itineraryString}`)} />
                </div>
              </div>
              <div className={styles.overview} onClick={() => setOverviewOpen(!overviewOpen)}>
                <div className={styles.title}>
                  <span>
                    Trip overview
                  </span>
                  <span className={styles.svg}>
                    <ArrowDown className={overviewOpen ? styles.rotated : ""} />
                  </span>
                </div>
                {overviewOpen && (
                  <span>
                    {data.overview}
                  </span>
                )}
              </div>
              <div className={styles.dayDiv}>
                {data?.tripItinerary?.map((day, index) => (
                  <Day
                    note={day.note?.length > 0 ? day.note : localStorage.getItem(`itineraryNote_${itineraryString}_day${index + 1}`)}
                    updateNote={updateNotes}
                    key={index}
                    noteSend={data.are_notes_sent}
                    id={index + 1}
                    imageUrl={day.place_photo_url}
                    startDate={moment(data.startDate, "MM/DD/YYYY").add(index, 'days').format("MM/DD/YYYY")} highlights={day.highlights}
                    start_city={day.start_city}
                    end_city={day.end_city}
                    title={day.title}
                    description={day.description}
                  />
                ))}
                <Button disabled={data.are_notes_sent} onClick={SendNotes} size={'medium'} className={cs(styles.sendNotesEnd, { [styles.active]: data.are_notes_sent ? false : noteSendActive })}>
                  <SendNote />
                  <div>
                    Send Notes
                    <span>to trip Designer</span>
                  </div>
                </Button>
              </div>
            </div>
            <div className={styles.map}>
              {!loading && !open &&
                <div className={styles.mapContainer}>
                  <Map
                    coordinates={coordinates}
                    coordinatesCenter={coordinatesCenter}
                    customMarker={customMarker}
                  />
                </div>
              }
            </div>
          </div>
        )}
      </div >
      {!loading &&
        <Popup
          open={open}
          modal
        >
          <div className={styles.modal}>
            <div onClick={closeModal} className={styles.overlay}></div>
            <div className={styles.modalContent}>
              <div className={styles.titleRow}>
                <h2>How it works</h2>
                <button className={styles.close} onClick={closeModal}>
                  &times;
                </button>
              </div>
              <div className={styles.steps}>
                <h4>
                  1. Review your generated itinerary
                </h4>
                <span>
                  This is just an example of what awaits at your destination, the real one will be collaborative between you and your Trip Designer.
                </span>
                <br />
                <br />
                <h4>
                  2. Leave feedback and notes
                </h4>
                <span>
                  on the itinerary and activities each day. Tell us what you like and what you don't, so your Designer can craft the perfect trip for you.
                </span>
                <Button
                  size={"medium"}
                  disabled={true}
                  className={cs(styles.btn, styles.active)}>
                  <AddNote />
                  <div>
                    Add Notes
                    <span>for trip Designer</span>
                  </div>
                </Button>
                <br />
                <h4>
                  3. Send your notes and draft itinerary
                </h4>
                <span>
                  to the Trip Designer and we'll take it from there!
                </span>
                <Button disabled={true} size={'medium'} className={cs(styles.btn, styles.active)}>
                  <SendNote />
                  <div>
                    Send Notes
                    <span>to trip Designer</span>
                  </div>
                </Button>
                <br />
              </div>
              <Button onClick={closeModal} className={styles.closeModal}>
                View my itinerary
              </Button>
            </div>
          </div>
        </Popup>
      }
      {<Popup
        open={spinner}
        modal
      >
        <div className={styles.modal}>
          <Spinner />
        </div>
      </Popup>}
      {<Popup
        open={openUnsavedNotes}
        modal
      >
        <div className={styles.modal}>
          <div onClick={closeModalNotes} className={styles.overlay}></div>
          <div className={styles.modalContent}>
            <div className={styles.titleRow}>
              <h2>You have unsaved notes for:</h2>
              <button className={styles.close} onClick={closeModalNotes}>
                &times;
              </button>
            </div>
            <p>
              {data.note.length > 0 && data.note_saved === false &&
                <>
                  <span>Whole trip</span>
                  <br />
                </>
              }
              {data.tripItinerary.map((day, index) => {
                if (day.note_saved && day.note.length > 0) {
                  return (
                    <>
                      <span key={index}>Day {index + 1}: {day.title}</span>
                      <br />
                    </>
                  );
                }
              })}
            </p>
            <div className={styles.titleRow}>
              <a onClick={closeModalNotes} className={styles.how}>
                Cancel
              </a>
              <Button onClick={sendNotesToDesigner} className={styles.btnNoSave}>
                Send without saving
              </Button>
              <Button onClick={saveAllNotes} className={styles.btnSave}>
                Save all and send
              </Button>
            </div>
          </div>
        </div>
      </Popup>}
    </>
  );
};




const mapStateToProps = state => ({
  customItinerary: state.customItinerary
});

const mapDispatchToProps = (dispatch) => ({
  updateCTCID: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'id',
      data
    });
  },
  updateCTCPrimaryCountry: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'primaryCountry',
      data
    });
  },
  updateCTCNote: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'note',
      data
    });
  },
  updateCTCItinerary: (data) => {
    dispatch({
      type: Constants.customItinerary.CHANGE,
      subject: 'Itinerary',
      data
    });
  },
});

Completed.propTypes = {
  customItinerary: T.object,
  updateCTCID: T.func,
  updateCTCPrimaryCountry: T.func,
  updateCTCItinerary: T.func,
  updateCTCNote: T.func,
};


export default connect(mapStateToProps, mapDispatchToProps)(Completed);
