import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import SpecificWishes from './specific_wishes';

const mapStateToProps = ({ selections }) => ({
  traveller: selections.travellerDetails,
  travel: selections.travelDetails,
  selections: selections
});


const mapDispatchToProps = (dispatch) => ({
  selectTravellerDetails: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'travellerDetails',
      data
    });
  },
  selectTravelDetails: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'travelDetails',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecificWishes);
