/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'stride-core-ui/button';
import Dialog from 'components/dialog';
import styles from './styles';
import BlockNavigation from '../../stride-core-ui/block_navigation';
import ErrorMessage from '../../components/error_message';
import { spawn } from 'redux-saga/effects';
import { ReactComponent as ClockIcon } from "assets/images/clock.svg";
import Header from 'components/header';
import ReactGA from 'react-ga4';

const BLOCKED_ROUTES = [{
  pathname: [
    '/tell-us-more-about-what-you-want-to-do',
    '/who-is-traveling',
    '/when-will-you-be-traveling',
    '/what-kind-of-trip-are-you-looking-for',
    '/what-type-of-lodging-do-you-want',
    '/signin',
    '/home-country',
    '/budget',
    '/completed'
  ],
  exact: false
}];

const urlParams = new URLSearchParams(location.search || '');
const urlLocation = urlParams.get('location') || '';

const GetStarted = ({ reset, location }) => {
  useEffect(() => {
    reset();
    localStorage.clear();
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Home page' });

  }, []);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // return (
  //   <Redirect to={ `/where-do-you-want-to-go${location.search}` } />
  // );

  const onButtonClick = () => {
    ReactGA.event({
      category: 'Get Started',
      action: 'Click',
      label: 'Start'
    });
    ReactGA
  }


  return (
    <>
      <Header isFirstStep={true} />
      <Dialog prepend={'CUSTOM TRIP PLANNER'}>
        <span className={styles.title}>
          Want to experience the world like never before? <span>Our Custom Trip<br />Planner</span> combines AI with the expertise of real travel<br />professionals to create a journey that's uniquely yours.
        </span>
        {/* {urlLocation === 'visitjordan' ? (
        <span>
          There's no obligation. FYI, custom trips are not eligible for the 20% special savings offer.
        </span>
      ) : (
        <span>
          There's no obligation.
        </span>
      )} */}

        <div className={styles.cta}>
          <Button
            as={Link}
            search={location.search}
            to={`/where-do-you-want-to-go${location.search}`}
            size={screenSize.width < 768 ? 'small' : 'medium'}
          >
            Start
          </Button>
          <div class='flex gap-2 items-end'>
            <ClockIcon />
            <span className={styles.text}>

              Around<span className={styles.time}>5</span>min
            </span>
          </div>
        </div>
      </Dialog>
    </>
  );
};

GetStarted.propTypes = {
  reset: T.func.isRequired,
  location: T.shape({ search: T.string })
};

GetStarted.defaultProps = {
  location: { search: '' }
};

export default GetStarted;
