import React, { useCallback, useState } from 'react';
import T from 'prop-types';
import { CalendarDay, DateRangePicker } from 'react-dates';
import { datesShape } from 'reducers/selections';
import RadioInput from 'stride-core-ui/radio_input';
import Moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import {
  lessThanSmallQuery
} from 'config/media';

import './styles.scss';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import cs from "classnames";
import styles from './styles';


const ExactDatesSelect = ({ data, select }) => {
  const onChange = useCallback(() => {
    select({ kind: 'exact', startDate: null, endDate: null });
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [maxEndDate, setMaxDate] = useState(null);
  const setDates = useCallback(({ startDate, endDate }) => {
    if (startDate) {
      setMaxDate(startDate.clone().add(16, 'days'));
    }
    select({ ...data, startDate, endDate });
  }, [data]);

  const active = data.kind === 'exact';
  const small = useMediaQuery({
    query: lessThanSmallQuery
  });

  const today = new Moment();


  return (
    <div className={cs(styles.inputRoot, { [styles.active]: active })}>
      <div className={styles.titleContainer} onClick={onChange}>
        <div className={styles.iconContainer}>
          <CalendarIcon />
        </div>
        <div className={styles.inputTitle}>
          I know my exact travel dates
          <span>{`Specific dates -> Select exact travel dates.`}</span>
        </div>
        <div className={cs(styles.closeContainer, {
          [styles.active]: active,
        })}>
          <ArrowDown />
        </div>
      </div>

      {
        active
          ? (
            <div className='flex flex-col w-[85%] mx-auto ps-4'>
              <div className={styles.calendarRoot}>
                <DateRangePicker
                  startDate={data.startDate}
                  startDateId="exact-start-date"
                  endDate={data.endDate}
                  endDateId="exact-end-date"
                  onDatesChange={setDates}
                  focusedInput={focusedInput}
                  onFocusChange={setFocusedInput}
                  readOnly
                  block
                  appendToBody
                  hideKeyboardShortcutsPanel
                  numberOfMonths={small ? 1 : 2}
                  verticalSpacing={0}
                  // orientation={small ? 'vertical' : 'horizontal'}
                  isOutsideRange={(date) => date.isSameOrBefore(today) || date.isAfter(maxEndDate)}
                // maxDate={maxDate}
                />
              </div>
            </div>
          ) : null
      }
    </div>
  );
};
ExactDatesSelect.propTypes = {
  data: datesShape.isRequired,
  select: T.func.isRequired
};

export default ExactDatesSelect;
