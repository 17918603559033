import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import Button from "stride-core-ui/button";

const ThingsToDo = ({ saveSelection, createItinerary }) => {
  const [text, setText] = useState("");

  const save = () => {
    if (text.length <= 0) {
      return;
    }
    saveSelection(7, text);
    createItinerary();
  };

  return (
    <div className={styles.root}>
      <textarea
        value={text}
        id="numInput"
        placeholder="Exploring Patagonia’s glaciers, visiting Rio de Janeiro’s Christ the Redeemer, and experiencing the Atacama Desert’s stargazing."
        className={styles.textarea}
        onChange={(e) => {
          setText(e.target.value);
        }}
        maxLength={3000}
      />
      <Button
        as={"button"}
        onClick={save}
        kind={"secondary"}
        disabled={text.length <= 0}
        className={text.length <= 0 ? styles.btn : styles.active}
        data-gtm-action="Step8: Where & What"
        data-gtm-category="Custom trip chat flow"
        data-gtm-label="Custom trip chat flow -> Where & What"
        data-gtm-trigger="click">
        Submit
      </Button>
    </div>
  );
};
ThingsToDo.propTypes = {
  saveSelection: T.func.isRequired,
  createItinerary: T.func.isRequired,
};
export default ThingsToDo;
