import React, { useEffect, useState } from 'react';
import { ReactComponent as StrideLogoImage } from 'assets/images/travel_stride_logo.svg';
import { ReactComponent as StrideLogoImageWhite } from 'assets/images/travel_stride_logo_white.svg';
import styles from './styles';

const StrideLogo = () => {

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <a href="/">
      {screenSize.width >= 1025 ?
        <StrideLogoImage className={styles.strideLogoImage} /> :
        <StrideLogoImageWhite className={styles.strideLogoImage} />
      }
    </a>
  );
};

export default StrideLogo;
