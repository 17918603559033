import createReducer from 'config/create_reducer';
import Constants from 'config/constants';
import update from 'immutability-helper';
import Moment from 'moment';

const handlers = {
  id: (state, data) => ({
    ...state,
    id: data
  }),
  locations: (state, data) => {
    // debugger;
    if (Array.isArray(data)) {
      // eslint-disable-next-line max-len
      return { ...state, locations: data?.filter((item) => !!item?.id && !!item?.countryName && !!item?.slug) ?? [] };
    }

    const index = state.locations.findIndex((l) => l.id === data.id);

    return index === -1
      ? update(state, { locations: { $push: [data] } })
      : update(state, { locations: { $splice: [[index, 1]] } });
  },
  moreLocations: (state, data) => {
    if (Array.isArray(data)) {
      // eslint-disable-next-line max-len
      return { ...state, moreLocations: data?.filter((item) => !!item?.id && !!item?.countryName && !!item?.slug) ?? [] };
    }
    const index = state.moreLocations.findIndex((l) => l.id === data.id);
    return index === -1
      ? update(state, { moreLocations: { $push: [data] } })
      : update(state, { moreLocations: { $splice: [[index, 1]] } });
  },

  specificWishes: (state, data) => ({
    ...state,
    specificWishes: data
  }),

  travelDetails: (state, data) => ({
    ...state,
    travelDetails: data
  }),

  travellerDetails: (state, data) => ({
    ...state,
    travellerDetails: data
  }),

  travelers: (state, data) => ({
    ...state,
    travelers: data
  }),

  dates: (state, data) => ({
    ...state,
    dates: data
  }),

  tripType: (state, data) => ({
    ...state,
    tripType: data
  }),

  lodging: (state, data) => ({
    ...state,
    lodging: data
  }),

  contactDetails: (state, data) => ({
    ...state,
    contactDetails: { ...state.contactDetails, ...data }
  }),

  budget: (state, data) => ({
    ...state,
    budget: data
  }),
  skipCountry: (state, data) => ({
    ...state,
    skipDestination: data
  })
};

const getDefaultState = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const flexipassInitiated = urlParams.get('flexipass') === 'true';

  return {
    id: undefined,
    submitting: false,
    locations: [],
    moreLocations: [],
    flexipassInitiated,
    specificWishes: {
      text: ''
    },
    travelDetails: { text: '' },
    travellerDetails: { text: '' },
    lodging: {},
    travelers: {
      travelersNumber: null,
      ages: [{
        name: '5 and under',
        value: false
      }, {
        name: '6 - 11',
        value: false
      }, {
        name: '12 - 17',
        value: false
      }, {
        name: '18 - 35',
        value: false
      }, {
        name: '36 - 49',
        value: false
      }, {
        name: '50 - 64',
        value: false
      }, {
        name: '65+',
        value: false
      }]
    },
    dates: {},
    tripType: {},
    contactDetails: {
      phoneNumber: '',
      country: ''
    },
    budget: {
      amount: undefined,
      type: undefined,
      planFlight: false
    },
    skipDestination: false
  };
};

export const fromPersistedState = (persistedState) => {
  const keys = [
    'id',
    'locations',
    'moreLocations',
    'specificWishes',
    'lodging',
    'travelers',
    'tripType',
    'contactDetails',
    'budget',
    'skipDestination',
    'travelDetails',
    'travellerDetails',

  ];

  const defaultState = getDefaultState();

  const out = keys.reduce((acc, key) => {
    acc[key] = persistedState[key];
    return acc;
  }, defaultState);

  out.dates.kind = persistedState.dates.kind;

  if (persistedState.dates.startDate) {
    out.dates.startDate = new Moment(persistedState.dates.startDate);
  }

  if (persistedState.dates.endDate) {
    out.dates.endDate = new Moment(persistedState.dates.endDate);
  }

  out.dates.departureMonth = persistedState.dates.departureMonth;

  out.dates.departureYear = persistedState.dates.departureYear;

  out.dates.duration = persistedState.dates.duration;

  return out;
};

const selections = createReducer(getDefaultState(), {
  [Constants.Selections.CHANGE](state, action) {
    if (handlers[action.subject]) {
      const nextState = handlers[action.subject](state, action.data);
      return nextState;
    }
    return state;
  },

  [Constants.Selections.SUBMIT](state) {
    return {
      ...state,
      error: undefined,
      submitting: true
    };
  },

  [Constants.Selections.SUBMIT_FAILURE](state, action) {
    return {
      ...state,
      error: action.error,
      submitting: false
    };
  },

  [Constants.Selections.SUBMIT_SUCCESS](state, action) {
    return {
      ...state,
      submitting: false,
      id: action.data.id
    };
  },

  [Constants.Selections.RESET]() {
    return {
      ...getDefaultState()
    };
  },

  [Constants.Selections.FETCH_LOCATION_IMAGE_SUCCESS](state, action) {
    const {
      data: { id, imageUrl }
    } = action;

    if (!imageUrl) {
      return state;
    }

    const index = state.locations.findIndex((item) => item.imageCategoryId === id);
    if (index === -1) {
      return state;
    }

    const locations = update(state.locations, {
      [index]: {
        imageUrl: {
          $set: imageUrl
        }
      }
    });

    return {
      ...state,
      locations
    };
  }
});

export default selections;
export * from './prop_types';
