import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import PhoneInput from "react-phone-input-2";
import { set } from "react-ga";
import styles from "./styles.module.scss";

const PhoneNumber = ({ saveSelection }) => {
  const [text, setText] = useState("");

  const save = () => {
    if (
      text.replace(/[^\d]/g, "").length < 10 ||
      text.replace(/[^\d]/g, "").length > 15
    ) {
      return;
    }
    saveSelection(11, text);
  };

  const onChangePhone = (value) => {
    setText(value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <PhoneInput
          inputProps={{
            name: "phone",
            required: true,
            autoComplete: "Phone Number",
          }}
          country="us"
          onChange={onChangePhone}
          values={text}
          preferredCountries={["us", "ca", "gb", "au"]}
          inputClass={styles.phoneInput}
        />
        <Button
          as="button"
          // onClick={ save }
          kind="secondary"
          disabled={
            text.replace(/[^\d]/g, "").length < 10 ||
            text.replace(/[^\d]/g, "").length > 15
          }
          type="submit"
          className={
            text.replace(/[^\d]/g, "").length < 10 ||
            text.replace(/[^\d]/g, "").length > 15
              ? styles.btn
              : styles.active
          }
          data-gtm-action="Step11: Phone Number"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> Phone Number"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
PhoneNumber.propTypes = {
  saveSelection: T.func.isRequired,
};
export default PhoneNumber;
