import React, { useEffect, useState, useRef, createRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  FeatureGroup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import { use } from "react";

const Map = ({
  coordinates,
  coordinatesCenter,
  customMarker,
  isMobile = false,
  isChat = false,
}) => {
  const getZoomLevel = (coordinates) => {
    const validCoordinates = coordinates.filter(
      (coord) =>
        Array.isArray(coord) &&
        coord.length === 2 &&
        coord[0] !== undefined &&
        coord[1] !== undefined
    );

    const defualtZoom = isChat ? 2 : 5;

    if (validCoordinates.length <= 1) return defualtZoom;

    const bounds = L.latLngBounds(
      validCoordinates.map((coord) => [coord[0], coord[1]])
    );

    const tempMapContainer = document.createElement("div");
    if (isMobile) {
      tempMapContainer.style.width = "100%"; // Set width to 100%
      tempMapContainer.style.height = "calc(60vh - 150px)"; // Set height to calc(100vh - 150px)
    } else {
      tempMapContainer.style.width = "50%"; // Set width to 100%
      tempMapContainer.style.height = "calc(90vh - 150px)";
    }
    document.body.appendChild(tempMapContainer); // Append to the document body

    const map = L.map(tempMapContainer); // Create a temporary map instance
    map.fitBounds(bounds);
    const zoom = map.getZoom();
    map.remove(); // Clean up the temporary map instance
    document.body.removeChild(tempMapContainer); // Remove the temporary container

    return zoom || defualtZoom;
  };
  const [zoom, setZoom] = useState(
    coordinates.length > 0 ? getZoomLevel(coordinates) : 2
  );
  const [center, setCenter] = useState(coordinatesCenter || [0, 0]);

  useEffect(() => {
    setCenter(coordinatesCenter);
  }, [coordinatesCenter]);

  useEffect(() => {
    if (coordinates.length > 1) {
      setZoom(getZoomLevel(coordinates));
    } else if (coordinates.length === 1) {
      setZoom(5);
    }
  }, [coordinates]);

  function SetViewOnClick({ center, zoom }) {
    const map = useMap();
    map.setView(center || [0, 0], zoom);

    return null;
  }
  // var Esri_NatGeoWorldMap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}', {
  //   attribution: 'Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC',
  //   maxZoom: 16
  // });
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      className="map"
      style={{ height: "100%", width: "100%" }}>
      <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}" />
      <SetViewOnClick center={center} zoom={zoom} />
      <FeatureGroup>
        {coordinates?.map((marker, index) => (
          <Marker index={index} position={marker} icon={customMarker} />
        ))}
      </FeatureGroup>
    </MapContainer>
  );
};

export default Map;
