import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import { DateRangePicker } from "react-dates";
import { useMediaQuery } from "react-responsive";
import { lessThanSmallQuery } from "config/media";
import Moment from "moment";

const ExcatDatePicker = ({ setDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [maxEndDate, setMaxDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const setDates = ({ startDate, endDate }) => {
    if (startDate) {
      setMaxDate(startDate.clone().add(16, "days"));
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const small = useMediaQuery({
    query: lessThanSmallQuery,
  });

  const save = () => {
    let answer = "";
    answer = `I'd like to travel from ${startDate.format(
      "MM/DD/YYYY"
    )} to ${endDate.format("MM/DD/YYYY")}.`;
    let sDay = `${startDate.format("MM/DD/YYYY")}`;
    let eDay = `${endDate.format("MM/DD/YYYY")}`;
    setDate(answer, sDay, eDay);
  };

  const today = new Moment();

  useEffect(() => {
    if (startDate && endDate) {
      save();
    }
  }, [startDate, endDate]);

  return (
    <div className={styles.root}>
      <span>{`Specific dates -> Select exact travel dates.`}</span>
      <DateRangePicker
        startDate={startDate}
        startDateId="exact-start-date"
        endDateId="exact-end-date"
        endDate={endDate}
        onDatesChange={setDates}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        appendToBody
        hideKeyboardShortcutsPanel
        numberOfMonths={small ? 1 : 2}
        verticalSpacing={0}
        isOutsideRange={(date) =>
          date.isSameOrBefore(today) || date.isAfter(maxEndDate)
        }
      />
    </div>
  );
};
ExcatDatePicker.propTypes = {
  setDate: T.func.isRequired,
};
export default ExcatDatePicker;
