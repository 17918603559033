import React, { useEffect, useState } from "react";
import T from "prop-types";
import {
  customSelectStyles,
  customSelectTheme,
} from "components/select/config";
import Select from "react-select";
import Button from "stride-core-ui/button";
import Api from "../../config/api";
import styles from "./styles.module.scss";

const Destinations = ({ saveSelection, updateAPIData }) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [optionsLoading, setLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    // ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Destination page' });

    setLoading(true);
    Api.getPHX("/v1/autocomplete/locations/countries")
      .then((response) => {
        const { json } = response;
        const countriesData = json.map(({ id, name, cover_image_url }) => ({
          value: id,
          label: name,
          image: cover_image_url,
        }));
        setSelectOptions(countriesData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const save = () => {
    if (selectedLocations.length === 0) {
      return;
    }
    let answer = "I'd like to visit ";
    const ids = [];
    selectedLocations.forEach((location, index) => {
      ids.push(location.value);
      if (
        index === selectedLocations.length - 1 &&
        selectedLocations.length > 1
      ) {
        answer += `and ${location.label}`;
      } else {
        answer += `${location.label}, `;
      }
    });
    if (selectedLocations.length === 1) {
      answer = `I'd like to visit ${selectedLocations[0].label}`;
    }
    const apiAnswer = selectedLocations[0].label;
    const apiAnswerTwo = [];
    if (selectedLocations.length > 1) {
      selectedLocations.forEach((location, index) => {
        if (index > 0) {
          apiAnswerTwo.push(location.label);
        }
      });
      updateAPIData(
        0,
        apiAnswer,
        apiAnswerTwo,
        ids,
        selectedLocations[0].image
      );
    } else {
      updateAPIData(0, apiAnswer, [], ids, selectedLocations[0].image);
    }
    saveSelection(0, answer);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <div className={styles.subtitle}>You can select multiple.</div>
        <Select
          closeMenuOnSelect
          options={selectOptions}
          isLoading={optionsLoading}
          hideSelectedOptions={false}
          placeholder="Destination country"
          theme={customSelectTheme}
          styles={customSelectStyles}
          menuPortalTarget={document.body}
          onChange={(selected) => {
            setSelectedLocations(selected);
          }}
          isMulti
          isClearable
          isSearchable
          menuPlacement="top"
        />
        <Button
          as="button"
          // onClick={ save }
          kind="secondary"
          disabled={selectedLocations?.length === 0}
          className={
            selectedLocations?.length === 0 ? styles.previous : styles.active
          }
          type="submit"
          data-gtm-action="Step1 : Destination"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> Destination"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
Destinations.propTypes = {
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
};
export default Destinations;
