import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import Button from "stride-core-ui/button";
import { ReactComponent as Independent } from "assets/images/chat-version/Independent.svg";
import { ReactComponent as PrivateGuided } from "assets/images/chat-version/PrivateGuided.svg";
import { ReactComponent as Group } from "assets/images/chat-version/Group.svg";
import { ReactComponent as Mixed } from "assets/images/chat-version/Mixed.svg";
import cs from "classnames";

const TripType = ({ saveSelection, updateAPIData }) => {
  const [dateType, setDateType] = useState([
    {
      id: 1,
      title: "Independent",
      name: "independent",
      text: "Explore on your own, at your own pace",
      icon: Independent,
      value: false,
    },
    {
      id: 2,
      name: "fully_guided",
      title: "Private Guided",
      text: "Have a local private guide whenever possible",
      icon: PrivateGuided,
      value: false,
    },
    {
      id: 3,
      title: "Group Tours",
      name: "shared_tour",
      text: "Spend most of your day joining small group tours, but still have some free time.",
      icon: Group,
      value: false,
    },
    {
      id: 4,
      title: "Mixed",
      name: "partially_guided",
      text: "Group or private guides for some highlights, but much of your time independent",
      icon: Mixed,
      value: false,
    },
  ]);

  const ontypeChange = (id) => {
    const newdateTypes = dateType.map((type) => {
      if (type.id === id) {
        return { ...type, value: !type.value };
      } else {
        return { ...type, value: false };
      }
    });
    setDateType(newdateTypes);
  };

  const save = () => {
    if (!dateType.some((type) => type.value)) {
      return;
    }
    let answer = "I'm looking for a ";
    let apiAnswer = "";
    dateType.forEach((type) => {
      if (type.value) {
        answer += type.title.toLocaleLowerCase() + " experience.";
        apiAnswer = type.name;
      }
    });
    updateAPIData(5, apiAnswer);
    saveSelection(5, answer);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {dateType.map((type) => (
          <div
            className={cs(styles.typeContainer, {
              [styles.active]: type.value,
            })}
            onClick={() => ontypeChange(type.id)}>
            <type.icon />
            <div className={styles.textContainer}>
              <h1>{type.title}</h1>
              <span>{type.text}</span>
            </div>
          </div>
        ))}
      </div>
      <Button
        as={"button"}
        onClick={save}
        kind={"secondary"}
        disabled={!dateType.some((type) => type.value)}
        className={cs(styles.btn, {
          [styles.active]: dateType.some((type) => type.value),
        })}
        data-gtm-action="Step6 : trip type"
        data-gtm-category="Custom trip chat flow"
        data-gtm-label="Custom trip chat flow -> trip type"
        data-gtm-trigger="click">
        Submit
      </Button>
    </div>
  );
};
TripType.propTypes = {
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
};
export default TripType;
