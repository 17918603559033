import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import styles from "./styles.module.scss";
import moment from "moment";
import cs from "classnames";
import Notes from "components/notes";
import { use } from "react";

const Day = ({
  id,
  start_city,
  end_city,
  startDate,
  title,
  description,
  highlights,
  imageUrl,
  updateNote,
  note,
  noteSend,
}) => {
  const [isOpened, setOpened] = useState(id === 1);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      name: "Overview",
      value: true,
    },
    {
      id: 2,
      name: "Lodging",
      value: false,
    },
    {
      id: 3,
      name: "Activities",
      value: false,
    },
    {
      id: 4,
      name: "Transport",
      value: false,
    },
    {
      id: 5,
      name: "Food",
      value: false,
    },
  ]);
  const endDate = moment(startDate, "MM/DD/YYYY")
    .add(1, "days")
    .format("MM/DD/YYYY");
  const handleOpened = () => {
    setOpened(!isOpened);
  };

  const getTitle = (isOpened) => {
    if (isOpened) {
      return (
        <>
          Day {id} - <span>{title}</span>
        </>
      );
    }

    return (
      <>
        Day {id} - {title}
      </>
    );
  };

  const getCity = (start_city, end_city) => {
    if (start_city == end_city) {
      return <>{start_city}</>;
    }

    return (
      <>
        {start_city} to <span>{end_city}</span>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <div
        className={cs(styles.dayContainer, {
          [styles.open]: isOpened,
        })}
        onClick={handleOpened}>
        <div className={styles.dayTitle}>
          <span className={styles.dayNumber}>{id}</span>
          <div className="flex flex-col w-2/3">
            <span className={styles.location}>{getTitle(isOpened)}</span>
            <span>
              {moment(startDate, "MM/DD/YYYY").isValid() &&
                moment(startDate, "MM/DD/YYYY").format("MMM D, YYYY")}
            </span>
          </div>
          <div className="flex flex-col justify-end w-1/3 text-end">
            <span className={styles.svg} onClick={handleOpened}>
              <ArrowDown className={isOpened ? styles.rotated : ""} />
            </span>
            <span className={styles.fromTo}>
              {getCity(start_city, end_city)}
            </span>
          </div>
        </div>
      </div>
      {isOpened && (
        <div className={styles.content}>
          <div className={styles.dayInfo}>
            <div class="text-center">
              <ul class="flex flex-wrap -mb-px gap-2">
                {tabs.map((tab) => (
                  <li
                    className="me-2"
                    onClick={() =>
                      setTabs(
                        tabs.map((t) =>
                          t.id === tab.id
                            ? { ...t, value: true }
                            : { ...t, value: false }
                        )
                      )
                    }>
                    <a
                      className={cs(styles.tabs, {
                        [styles.active]: tab.value,
                      })}>
                      {tab.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {tabs[0].value && (
              <>
                <div className={styles.list}>
                  {highlights &&
                    highlights.map((highlight, index) => (
                      <span key={index}>{highlight}</span>
                    ))}
                </div>
                <span className={styles.description}>{description}</span>
              </>
            )}
            {tabs[1].value && (
              <>
                <span className={styles.description}>
                  Let us know your lodging preferences whether you prefer
                  hotels, Airbnbs, homestays, or something else so we can tailor
                  your stay to your style!
                </span>
              </>
            )}
            {tabs[2].value && (
              <>
                <span className={styles.description}>
                  Let us know what excites you drop a comment with any must-do
                  activities or special interests for your trip designer!
                </span>
              </>
            )}
            {tabs[3].value && (
              <>
                <span className={styles.description}>
                  Any special way you prefer to travel? Plane, train, boat, or
                  anything in between let us know.
                </span>
              </>
            )}
            {tabs[4].value && (
              <>
                <span className={styles.description}>
                  Let us know your dining preferences, and we’ll tailor
                  recommendations to match your taste!
                </span>
              </>
            )}
          </div>
          <div className={styles.dayDetails}>
            <div className={styles.image}>
              <img src={imageUrl} />
            </div>
            <Notes
              noteSend={noteSend}
              updateNote={updateNote}
              id={id}
              defaultNote={note}
              placeholder="What would you like the trip designer to know for this day specifically?"
            />
          </div>
        </div>
      )}
    </div>
  );
};
Day.propTypes = {
  id: T.number.isRequired,
  start_city: T.string.isRequired,
  end_city: T.string.isRequired,
  startDate: T.string.isRequired,
  title: T.string.isRequired,
  description: T.string.isRequired,
  highlights: T.arrayOf(T.string).isRequired,
  imageUrl: T.string.isRequired,
  updateNote: T.func.isRequired,
  note: T.string,
  noteSend: T.bool,
};
export default Day;
