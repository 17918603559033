import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import moment from "moment";
import cs from "classnames";
import { ReactComponent as RoverIcon } from "assets/images/chat-version/rover-circle.svg";
import { set } from "react-ga";
import Typed from "react-typed";
import styles from "./styles.module.scss";
import RoverMessage from "./rover_message";
import UserMessage from "./user_message";

const ChatBot = ({
  currentStep,
  updateContactData,
  steps,
  saveSelection,
  next,
  updateAPIData,
  createItinerary,
  primatyCountry,
  ctcID,
  isMobile,
}) => {
  const [saved, setSaved] = useState(false);
  const [introOne, setIntroOne] = useState(false);
  const [introTwo, setIntroTwo] = useState(isMobile ? true : false);
  const [introThree, setIntroThree] = useState(isMobile ? true : false);

  useEffect(() => {
    if (currentStep === 8) {
      setTimeout(() => {
        next();
      }, 1000);
    }
  }, [currentStep]);

  const redirect = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    window.location.href = `${baseUrl}/my-custom-trip/completed?itinerary=${ctcID}&primary_country=${primatyCountry}`;
  };
  return (
    <div className={styles.root}>
      <div className={styles.introContainer}>
        <RoverIcon className={styles.rover} />
        <RoverMessage
          text={"Hi, I'm Rover, your friendly travel AI guide."}
          typeSpeed={5}
          onComplete={() => setIntroOne(true)}
        />
        {!isMobile && (
          <>
            {introOne && (
              <RoverMessage
                text={
                  "I'm here to plan you an extraordinary trip. After we go through a few questions, I will create your draft itinerary instantly."
                }
                typeSpeed={5}
                onComplete={() => setIntroTwo(true)}
              />
            )}
            {introTwo && (
              <RoverMessage
                text={
                  "Then I'll match you with a human local expert who will refine it with you and even book it if you like."
                }
                typeSpeed={5}
                onComplete={() => setIntroThree(true)}
              />
            )}
          </>
        )}
      </div>
      {introOne && introTwo && introThree && (
        <>
          {steps.map((step, index) => (
            <>
              {currentStep >= index && (
                <div className={styles.messageContainer}>
                  {currentStep >= index && (
                    <RoverMessage text={step.title} typeSpeed={20} />
                  )}
                  {currentStep >= index && step.answer.length > 0 && (
                    <UserMessage text={step.answer} />
                  )}
                </div>
              )}
              {currentStep === index && currentStep != 8 && (
                // <span>test</span>
                <>
                  <step.question
                    saveSelection={saveSelection}
                    updateAPIData={updateAPIData}
                    createItinerary={index === 7 && createItinerary}
                    updateContactData={index === 15 && updateContactData}
                  />
                  {!isMobile && <br />}
                </>
              )}
            </>
          ))}
        </>
      )}
      {currentStep === 16 &&
        primatyCountry?.length > 0 &&
        ctcID?.length > 0 && (
          <>
            <div className={styles.itineraryLink}>
              Great, we're done. Are you ready to see your itinerary?
              <Button
                as="button"
                onClick={redirect}
                kind="secondary"
                data-gtm-action="Are you ready to see your itinerary?"
                data-gtm-category="Custom trip chat flow"
                data-gtm-label="Custom trip chat flow -> Are you ready to see your itinerary?"
                data-gtm-trigger="click"
                className={cs(styles.btn, styles.active)}>
                Yes
              </Button>
            </div>
            <br />
          </>
        )}
    </div>
  );
};

ChatBot.propTypes = {
  currentStep: T.number.isRequired,
  // selector: T.elementType.isRequired,
  steps: T.array.isRequired,
  saveSelection: T.func.isRequired,
  updateAPIData: T.func.isRequired,
  updateContactData: T.func.isRequired,
  createItinerary: T.func.isRequired,
  next: T.func.isRequired,
  primatyCountry: T.string,
  ctcID: T.string,
  isMobile: T.bool,
};

export default ChatBot;
