import createReducer from 'config/create_reducer';
import Constants from 'config/constants';
import Moment from 'moment';

const handlers = {
  id: (state, data) => ({
    ...state,
    id: data
  }),
  primaryCountry: (state, data) => ({
    ...state,
    primaryCountry: data
  }),
  Itinerary: (state, data) => ({
    ...state,
    Itinerary: data
  }),
  note: (state, data) => ({
    ...state,
    note: data
  }),
};

export const getDefaultState = () => {
  return {
    id: '',
    primaryCountry: '',
    note: '',
    Itinerary: [
      {
        day: '',
        activities: [],
        description: '',
        end_city: '',
        end_city_coordinates: [],
        end_country: '',
        highlights: [],
        place_photo_url: '',
        start_city: '',
        start_city_coordinates: [],
        start_country: '',
        title: '',
        note: '',
      }
    ],
  };
};

export const fromPersistedState = (persistedState) => {
  const keys = [
    'id',
    'primaryCountry',
    'Itinerary',
    'note'
  ];

  const defaultState = getDefaultState();

  const out = keys.reduce((acc, key) => {
    acc[key] = persistedState[key];
    return acc;
  }, defaultState);

  out.dates.kind = persistedState.dates.kind;

  if (persistedState.dates.startDate) {
    out.dates.startDate = new Moment(persistedState.dates.startDate);
  }

  if (persistedState.dates.endDate) {
    out.dates.endDate = new Moment(persistedState.dates.endDate);
  }

  out.dates.departureMonth = persistedState.dates.departureMonth;

  out.dates.departureYear = persistedState.dates.departureYear;

  out.dates.duration = persistedState.dates.duration;

  return out;
};

const customItinerary = createReducer(getDefaultState(), {
  [Constants.customItinerary.CHANGE](state, action) {
    if (handlers[action.subject]) {
      const nextState = handlers[action.subject](state, action.data);
      return nextState;
    }
    return state;
  },

  [Constants.customItinerary.SAVE](state) {
    return {
      ...state,
      error: undefined,
      saveing: true
    };
  },

  [Constants.customItinerary.SAVE_FAILURE](state, action) {
    return {
      ...state,
      error: action.error,
      saveing: false
    };
  },

  [Constants.customItinerary.SAVE_SUCCESS](state, action) {
    return {
      ...state,
      saveing: false,
      id: action.data.id
    };
  },

});

export default customItinerary;