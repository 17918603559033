import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as Edit } from "assets/images/chat-version/edit.svg";
import styles from "./styles.module.scss";
import moment from "moment";
import cs from "classnames";
import { use } from "react";

const Summary = ({ steps, setCurrentStep }) => {
  return (
    <div className={styles.root}>
      {steps.map((step, index) => (
        <>
          {step.answer.length > 0 && (
            <div
              className={styles.stepContainer}
              onClick={() => setCurrentStep(index)}>
              {step.title === "Who’s traveling, and what’s your travel vibe?" &&
                "Who & Why"}
              {step.title ===
                "What cities, attractions, or activities are 'must-haves'?" &&
                "Where & What"}
              {step.title != "Who’s traveling, and what’s your travel vibe?" &&
                step.title !=
                  "What cities, attractions, or activities are 'must-haves'?" &&
                step.answer}
              <Edit />
            </div>
          )}
        </>
      ))}
    </div>
  );
};

Summary.propTypes = {
  steps: T.array.isRequired,
  setCurrentStep: T.func.isRequired,
};

export default Summary;
