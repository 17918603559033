import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import Button from "stride-core-ui/button";
import { ReactComponent as Early } from "assets/images/chat-version/early.svg";
import { ReactComponent as Confident } from "assets/images/chat-version/confident.svg";
import { ReactComponent as ReadyToBook } from "assets/images/chat-version/ReadyToBook.svg";
import cs from "classnames";

const PlanningStage = ({ saveSelection, updateContactData }) => {
  const [dateType, setDateType] = useState([
    {
      id: 1,
      name: "Just exploring",
      text: "I’m gathering ideas and seeing what’s out there.",
      icon: Early,
      value: false,
    },
    {
      id: 2,
      name: "Making plans",
      text: "I have a good idea of dates and locations",
      icon: Confident,
      value: false,
    },
    {
      id: 3,
      name: "Almost ready to book",
      text: "Location and dates are set, just need to refine the details and get pricing.",
      icon: ReadyToBook,
      value: false,
    },
  ]);

  const ontypeChange = (id) => {
    const newdateTypes = dateType.map((type) => {
      if (type.id === id) {
        return { ...type, value: !type.value };
      } else {
        return { ...type, value: false };
      }
    });
    setDateType(newdateTypes);
  };

  const save = () => {
    if (!dateType.some((type) => type.value)) {
      return;
    }
    let answer = "I'm ";
    dateType.forEach((type) => {
      if (type.value) {
        answer += type.name.toLocaleLowerCase() + ", ";
      }
    });
    answer = answer.slice(0, -2);
    saveSelection(15, answer);
    updateContactData();
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {dateType.map((type) => (
          <div
            className={cs(styles.typeContainer, {
              [styles.active]: type.value,
            })}
            onClick={() => ontypeChange(type.id)}>
            <type.icon />
            <h1>{type.name}</h1>
            <span>{type.text}</span>
          </div>
        ))}
      </div>
      <Button
        as={"button"}
        onClick={save}
        kind={"secondary"}
        disabled={!dateType.some((type) => type.value)}
        className={cs(styles.btn, {
          [styles.active]: dateType.some((type) => type.value),
        })}
        data-gtm-action="Step15: Planning stage"
        data-gtm-category="Custom trip chat flow"
        data-gtm-label="Custom trip chat flow -> Planning stage"
        data-gtm-trigger="click">
        Submit
      </Button>
    </div>
  );
};
PlanningStage.propTypes = {
  saveSelection: T.func.isRequired,
  updateContactData: T.func.isRequired,
};
export default PlanningStage;
