import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import Select from 'react-select';
import { datesShape } from 'reducers/selections';
import RadioInput from 'stride-core-ui/radio_input';
import { customSelectStyles, customSelectTheme } from 'components/select/config';
import styles from './styles';
import { ReactComponent as QuestionIcon } from 'assets/images/question.svg';
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import cs from "classnames";

const OPTIONS = [];
for (let i = 1; i <= 16; i += 1) {
  OPTIONS.push({
    label: `${i} day${i > 1 ? 's' : ''}`,
    value: i
  });
}

const DurationSelect = ({ data, select }) => {
  const onChange = useCallback(() => {
    select({ kind: 'duration', startDate: null, endDate: null });
  });

  const setDuration = useCallback((duration) => {
    select({
      ...data,
      duration
    });
  }, [data]);

  const active = data.kind === 'duration';

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={cs(styles.inputRoot, { [styles.active]: active })}>
      <div className={styles.titleContainer} onClick={onChange}>
        <div className={styles.iconContainer}>
          <QuestionIcon />
        </div>
        <div className={styles.inputTitle}>
          I haven’t chosen my dates yet
          <span>Haven’t decided yet? Just give us an ideal duration.</span>
        </div>
        <div className={cs(styles.closeContainer, {
          [styles.active]: active,
        })}>
          <ArrowDown />
        </div>
      </div>
      {
        active
          ? (
            <div className='flex flex-col w-[85%] mx-auto ps-4'>
              <div className={styles.inputs}>
                <Select
                  value={data.duration}
                  onChange={setDuration}
                  options={OPTIONS}
                  placeholder="Duration"
                  className={styles.select}
                  isSearchable={false}
                  styles={customSelectStyles}
                  theme={customSelectTheme}
                />
              </div>
            </div>
          ) : null
      }
    </div>
  );
};
DurationSelect.propTypes = {
  data: datesShape.isRequired,
  select: T.func.isRequired
};

export default DurationSelect;
