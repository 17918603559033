import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./styles.module.scss";
import Button from "stride-core-ui/button";

const TravelVibe = ({ saveSelection }) => {
  const [text, setText] = useState("");

  const save = () => {
    if (text.length <= 0) {
      return;
    }
    saveSelection(6, text);
  };

  return (
    <div className={styles.root}>
      <textarea
        value={text}
        id="numInput"
        placeholder="Two friends looking for an adventurous, off-the-beaten-path experience with lots of hiking and cultural immersion."
        className={styles.textarea}
        onChange={(e) => {
          setText(e.target.value);
        }}
        maxLength={3000}
      />
      <Button
        as={"button"}
        onClick={save}
        kind={"secondary"}
        disabled={text.length <= 0}
        className={text.length <= 0 ? styles.btn : styles.active}
        data-gtm-action="Step7: Who & Why"
        data-gtm-category="Custom trip chat flow"
        data-gtm-label="Custom trip chat flow -> Who & Why"
        data-gtm-trigger="click">
        Submit
      </Button>
    </div>
  );
};
TravelVibe.propTypes = {
  saveSelection: T.func.isRequired,
};
export default TravelVibe;
