import React, { useEffect, useState } from "react";
import T from "prop-types";
import Button from "stride-core-ui/button";
import Select from "react-select";
import {
  customSelectStylesContactDetails,
  customSelectTheme,
} from "components/select/config";
import styles from "./styles.module.scss";

const selectOptionsMobile = [
  {
    id: 0,
    label: "",
    options: [
      { value: 184, label: "United States" },
      { value: 7, label: "United Kingdom" },
      { value: 24, label: "Canada" },
      { value: 22, label: "Australia" },
    ],
  },
  {
    id: 1,
    label: "Europe",
    options: [
      { value: 2, label: "Italy" },
      { value: 3, label: "Greece" },
      { value: 4, label: "Spain" },
      { value: 5, label: "France" },
      { value: 6, label: "Germany" },
      { value: 8, label: "Switzerland" },
      { value: 9, label: "Austria" },
      { value: 10, label: "Portugal" },
      { value: 11, label: "Belgium" },
      { value: 12, label: "Netherlands" },
      { value: 13, label: "Norway" },
      { value: 14, label: "Denmark" },
      { value: 15, label: "Sweden" },
      { value: 16, label: "Finland" },
      { value: 17, label: "Hungary" },
      { value: 18, label: "Poland" },
      { value: 19, label: "Croatia" },
    ],
  },
  {
    id: 2,
    label: "North America",
    options: [
      { value: 184, label: "United States" },
      { value: 166, label: "Suriname" },
      { value: 68, label: "Bahamas" },
    ],
  },
  {
    id: 3,
    label: "Central America",
    options: [
      { value: 58, label: "El Salvador" },
      { value: 89, label: "Dominica" },
      { value: 101, label: "Grenada" },
      { value: 105, label: "Haiti" },
      { value: 152, label: "Saint Vincent and the Grenadines" },
      { value: 172, label: "Trinidad and Tobago" },
      { value: 29, label: "Mexico" },
      { value: 51, label: "Jamaica" },
      { value: 52, label: "Cuba" },
      { value: 53, label: "Costa Rica" },
      { value: 54, label: "Panama" },
      { value: 55, label: "Guatemala" },
      { value: 56, label: "Belize" },
      { value: 57, label: "Honduras" },
      { value: 59, label: "Nicaragua" },
      { value: 65, label: "Antigua and Barbuda" },
      { value: 71, label: "Barbados" },
      { value: 90, label: "Dominican Republic" },
      { value: 104, label: "Guyana" },
      { value: 150, label: "Saint Kitts and Nevis" },
      { value: 151, label: "Saint Lucia" },
    ],
  },
  {
    id: 4,
    label: "South America",
    options: [
      { value: 21, label: "Argentina" },
      { value: 23, label: "Brazil" },
      { value: 43, label: "Chile" },
      { value: 44, label: "Peru" },
      { value: 45, label: "Colombia" },
      { value: 46, label: "Venezuela" },
      { value: 47, label: "Ecuador" },
      { value: 48, label: "Bolivia" },
      { value: 49, label: "Uruguay" },
      { value: 50, label: "Paraguay" },
    ],
  },
  {
    id: 5,
    label: "East Asia",
    options: [
      { value: 25, label: "China" },
      { value: 28, label: "Japan" },
      { value: 38, label: "South Korea" },
      { value: 132, label: "Mongolia" },
    ],
  },
  {
    id: 6,
    label: "Southeast Asia",
    options: [
      { value: 77, label: "Brunei" },
      { value: 91, label: "East Timor" },
      { value: 27, label: "Indonesia" },
      { value: 32, label: "Thailand" },
      { value: 39, label: "Malaysia" },
      { value: 40, label: "Philippines" },
      { value: 41, label: "Vietnam" },
      { value: 80, label: "Cambodia" },
      { value: 116, label: "Laos" },
      { value: 125, label: "Maldives" },
      { value: 136, label: "Myanmar" },
      { value: 139, label: "Nepal" },
      { value: 159, label: "Singapore" },
    ],
  },
  {
    id: 7,
    label: "South Asia",
    options: [
      { value: 60, label: "Afghanistan" },
      { value: 70, label: "Bangladesh" },
      { value: 144, label: "Pakistan" },
      { value: 168, label: "Tajikistan" },
      { value: 26, label: "India" },
      { value: 74, label: "Bhutan" },
      { value: 164, label: "Sri Lanka" },
      { value: 174, label: "Turkmenistan" },
      { value: 179, label: "Uzbekistan" },
    ],
  },
  {
    id: 8,
    label: "West Asia",
    options: [
      { value: 36, label: "Iran" },
      { value: 69, label: "Bahrain" },
      { value: 107, label: "Iraq" },
      { value: 114, label: "Kuwait" },
      { value: 117, label: "Lebanon" },
      { value: 167, label: "Syria" },
      { value: 181, label: "Yemen" },
      { value: 143, label: "Oman" },
      { value: 33, label: "Turkey" },
      { value: 35, label: "Saudi Arabia" },
      { value: 37, label: "Israel" },
      { value: 66, label: "Armenia" },
      { value: 67, label: "Azerbaijan" },
      { value: 99, label: "Georgia" },
      { value: 110, label: "Jordan" },
      { value: 111, label: "Kazakhstan" },
      { value: 115, label: "Kyrgyzstan" },
      { value: 147, label: "Qatar" },
      { value: 178, label: "United Arab Emirates" },
    ],
  },
  {
    id: 9,
    label: "North Africa",
    options: [
      { value: 62, label: "Algeria" },
      { value: 120, label: "Libya" },
      { value: 31, label: "Egypt" },
      { value: 134, label: "Morocco" },
      { value: 173, label: "Tunisia" },
    ],
  },
  {
    id: 10,
    label: "Sub-Saharan Africa",
    options: [
      { value: 64, label: "Angola" },
      { value: 73, label: "Benin" },
      { value: 78, label: "Burkina Faso" },
      { value: 81, label: "Cameroon" },
      { value: 82, label: "Cape Verde" },
      { value: 83, label: "Central African Republic" },
      { value: 84, label: "Chad" },
      { value: 85, label: "Comoros" },
      { value: 86, label: "Democratic Republic of the Congo" },
      { value: 87, label: "Republic of the Congo" },
      { value: 88, label: "Djibouti" },
      { value: 92, label: "Equatorial Guinea" },
      { value: 93, label: "Eritrea" },
      { value: 94, label: "Eswatini" },
      { value: 95, label: "Ethiopia" },
      { value: 97, label: "Gabon" },
      { value: 98, label: "Gambia" },
      { value: 102, label: "Guinea" },
      { value: 103, label: "Guinea-Bissau" },
      { value: 109, label: "Ivory Coast" },
      { value: 118, label: "Lesotho" },
      { value: 119, label: "Liberia" },
      { value: 126, label: "Mali" },
      { value: 128, label: "Mauritania" },
      { value: 140, label: "Niger" },
      { value: 141, label: "Nigeria" },
      { value: 155, label: "Sao Tome and Principe" },
      { value: 156, label: "Senegal" },
      { value: 158, label: "Sierra Leone" },
      { value: 163, label: "Somalia" },
      { value: 165, label: "Sudan" },
      { value: 170, label: "Togo" },
      { value: 34, label: "South Africa" },
      { value: 76, label: "Botswana" },
      { value: 79, label: "Burundi" },
      { value: 100, label: "Ghana" },
      { value: 112, label: "Kenya" },
      { value: 123, label: "Madagascar" },
      { value: 124, label: "Malawi" },
      { value: 129, label: "Mauritius" },
      { value: 135, label: "Mozambique" },
      { value: 137, label: "Namibia" },
      { value: 149, label: "Rwanda" },
      { value: 157, label: "Seychelles" },
      { value: 169, label: "Tanzania" },
      { value: 176, label: "Uganda" },
      { value: 182, label: "Zambia" },
      { value: 183, label: "Zimbabwe" },
    ],
  },
  {
    id: 11,
    label: "Eastern Europe",
    options: [
      { value: 20, label: "Czech Republic" },
      { value: 63, label: "Andorra" },
      { value: 72, label: "Belarus" },
      { value: 154, label: "San Marino" },
      { value: 30, label: "Russia" },
      { value: 61, label: "Albania" },
      { value: 75, label: "Bosnia and Herzegovina" },
      { value: 106, label: "Iceland" },
      { value: 108, label: "Ireland" },
      { value: 121, label: "Liechtenstein" },
      { value: 122, label: "Luxembourg" },
      { value: 127, label: "Malta" },
      { value: 131, label: "Monaco" },
      { value: 133, label: "Montenegro" },
      { value: 142, label: "North Macedonia" },
      { value: 148, label: "Romania" },
      { value: 160, label: "Slovakia" },
      { value: 161, label: "Slovenia" },
      { value: 177, label: "Ukraine" },
    ],
  },
  {
    id: 12,
    label: "Oceania",
    options: [
      { value: 113, label: "Kiribati" },
      { value: 130, label: "Micronesia" },
      { value: 138, label: "Nauru" },
      { value: 145, label: "Palau" },
      { value: 162, label: "Solomon Islands" },
      { value: 175, label: "Tuvalu" },
      { value: 42, label: "New Zealand" },
      { value: 96, label: "Fiji" },
      { value: 146, label: "Papua New Guinea" },
      { value: 153, label: "Samoa" },
      { value: 171, label: "Tonga" },
      { value: 180, label: "Vanuatu" },
    ],
  },
];

const HomeCountry = ({ saveSelection }) => {
  const [text, setText] = useState("");

  const save = () => {
    if (text.length <= 0) {
      return;
    }
    saveSelection(13, text);
  };

  const handleChange = (e) => {
    setText(e.label);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.root}>
        <Select
          className={styles.input}
          isSearchable
          onChange={handleChange}
          options={selectOptionsMobile}
          placeholder="select"
          styles={customSelectStylesContactDetails}
          theme={customSelectTheme}
          menuPlacement="top"
        />
        <Button
          as="button"
          // onClick={save}
          type="submit"
          kind="secondary"
          disabled={text.length <= 0}
          className={text.length <= 0 ? styles.btn : styles.active}
          data-gtm-action="Step13: Home Country"
          data-gtm-category="Custom trip chat flow"
          data-gtm-label="Custom trip chat flow -> Home Country"
          data-gtm-trigger="click">
          Submit
        </Button>
      </div>
    </form>
  );
};
HomeCountry.propTypes = {
  saveSelection: T.func.isRequired,
};
export default HomeCountry;
